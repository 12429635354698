<template>
  <FormContainer>
    <div class="mb-5">
      <FormGroup
        :form="forms.SCENARIO_FORM"
        label="Telefonní číslo:"
        name="phoneNumber"
        v-model="phoneNumber"
        className="form-group--neutral-label"
        :validate="[validations.noEmpty, validations.phoneNumber]"
      />
    </div>
    <div class="pt-3">
      <FormGroup
        :form="forms.SCENARIO_FORM"
        :rows="3"
        label="Bot (uvítání):"
        name="greetings"
        v-model="greetings"
        :validate="[validations.noEmpty]"
      />
    </div>
    <div class="mt-4 mb-4">
      <Title :level="4" className="color--theme-green">Bot:</Title>
      <TextBlock type="span" class="d-block text-block--sublabel mt-2">
        Pokud budete chtít vzkaz zanechat, mluvte. V opačném případě prosím
        zavěste.
      </TextBlock>
    </div>
    <div class="mt-4 mb-4">
      <Title :level="4" className="color--theme-orange">Volající:</Title>
      <TextBlock type="span" class="d-block text-block--sublabel mt-2">
        <i>Diktuje do telefonu vzkaz.</i>
      </TextBlock>
    </div>
    <div class="mt-4 mb-4">
      <Title :level="4" className="color--theme-green">Bot:</Title>
      <TextBlock type="span" class="d-block text-block--sublabel mt-2">
        Dobře. Nyní poprosím o Vaše jméno.
      </TextBlock>
    </div>
    <div class="mt-4 mb-3">
      <Title :level="4" className="color--theme-orange">Volající:</Title>
      <TextBlock type="span" class="d-block text-block--sublabel mt-2">
        <i>Diktuje svoje jméno.</i>
      </TextBlock>
    </div>
    <FormGroup
      :form="forms.SCENARIO_FORM"
      :rows="2"
      label="Bot (rozloučení):"
      name="farewell"
      v-model="farewell"
      :validate="[validations.noEmpty]"
    />
    <Button
      theme="link"
      className="mt-5"
      :actionHandler="
        e => {
          e.preventDefault();
          resetFormToDefaut();
        }
      "
    >
      Vrátit původní nastavení
    </Button>
  </FormContainer>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import mapFields from "@/helpers/mapFields";
import { noEmpty, phoneNumber } from "@/helpers/validations";
import { SCENARIO_FORM, SCENARIO_FORM_DEFAULT } from "@/constants/forms";
import { FORM_MODULE, USER_MODULE, ORDER_MODULE } from "@/constants/modules";
import { SUBMIT_FORM, EDIT_SCENARIO } from "@/constants/actions";

import FormContainer from "@/components/FormContainer";
import FormGroup from "@/components/FormGroup";
import Button from "@/components/Button";
import TextBlock from "@/components/TextBlock";
import Title from "@/components/Title";

import "./ScenarioForm.scss";

export default {
  data: () => ({
    validations: { noEmpty, phoneNumber },
    forms: { SCENARIO_FORM }
  }),
  name: "ScenarioForm",
  components: {
    FormContainer,
    FormGroup,
    Button,
    TextBlock,
    Title
  },
  props: {
    scenarioData: Object
  },
  methods: {
    ...mapMutations(FORM_MODULE, ["setFormValues"]),
    ...mapActions(ORDER_MODULE, [EDIT_SCENARIO]),
    ...mapActions(FORM_MODULE, [SUBMIT_FORM]),

    sendScenarioForm() {
      const scenarioId = this.$route.params.id;
      const notification = [];

      if (this.currentTariff.data.featureEmail) {
        notification.push({
          type: "email",
          contact: this.userEmail
        });
      }

      if (this.currentTariff.data.featureSms) {
        notification.push({
          type: "sms",
          contact: this.phoneNumber
        });
      }

      this[SUBMIT_FORM]({
        form: SCENARIO_FORM,
        action: data =>
          this[EDIT_SCENARIO]({
            id: scenarioId ? scenarioId : "",
            notification: JSON.stringify(notification),
            phoneNumber: this.phoneNumber,
            scenarioData: JSON.stringify(data)
          })
      });
    },

    resetFormToDefaut() {
      const confirmAlert = window.confirm(
        "Opravdu chcete vrátit data do původního nastavení?"
      );
      confirmAlert
        ? this.setFormValues({
            form: SCENARIO_FORM,
            values: SCENARIO_FORM_DEFAULT
          })
        : null;
    }
  },
  computed: {
    ...mapGetters(USER_MODULE, ["userEmail"]),
    ...mapGetters(ORDER_MODULE, ["currentTariff"]),
    ...mapFields({
      form: SCENARIO_FORM,
      fields: ["greetings", "farewell", "phoneNumber"]
    })
  },
  mounted() {
    this.scenarioData
      ? this.setFormValues({ form: SCENARIO_FORM, values: this.scenarioData })
      : null;
  }
};
</script>
