<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col
          v-if="errorRedirect || isActionError(actions.VERIFY_RECEIVED_PAYMENT)"
          class="text-center"
        >
          <Title :level="1" className="mb-5"
            >Platba selhala, zkuste to znovu</Title
          >
          <Button
            theme="primary"
            isActive
            className="mt-5"
            :actionHandler="() => this.$router.push('/order-payment')"
          >
            Zpět k platbě
          </Button>
        </b-col>
        <b-col
          v-if="isActionFetching(actions.VERIFY_RECEIVED_PAYMENT)"
          class="text-center"
        >
          <Title :level="1" className="mb-5">Probíhá ověřování platby...</Title>
          <Loader />
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import Button from "@/components/Button";
import Loader from "@/components/Loader";

import { API_MODULE, ORDER_MODULE } from "@/constants/modules";
import { VERIFY_RECEIVED_PAYMENT } from "@/constants/actions";

export default {
  name: "VerifyPayment",
  components: {
    PageLayout,
    Title,
    Section,
    Button,
    Loader
  },
  data: () => ({
    errorRedirect: false,
    actions: { VERIFY_RECEIVED_PAYMENT }
  }),
  methods: {
    ...mapActions(ORDER_MODULE, [VERIFY_RECEIVED_PAYMENT]),

    verifyPayment() {
      const { success, ipRating, status, ...queryParams } = this.$route.query;

      success === "false"
        ? (this.errorRedirect = true)
        : this[VERIFY_RECEIVED_PAYMENT]({
            status: parseInt(status),
            ...(ipRating !== "" && ipRating),
            ...queryParams
          });
    }
  },
  computed: {
    ...mapGetters(API_MODULE, ["isActionFetching", "isActionError"])
  },
  mounted() {
    this.verifyPayment();
  }
};
</script>
