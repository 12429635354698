<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="8" offset-md="2">
          <Title :level="1" className="mb-5">Děkujeme za dotaz!</Title>
          <TextBlock type="p" className="pt-5 pb-5"
            >Vaši zprávu jsme obdrželi a v nejbližší možné době Vás budeme
            kontaktovat.</TextBlock
          >
          <Button
            theme="primary"
            className="mt-5"
            isActive
            :actionHandler="() => this.$router.push('/order-overview')"
          >
            Zpět na přehled
          </Button>
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";

export default {
  name: "ContacSend",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    Button
  }
};
</script>
