<template>
  <form class="form">
    <slot />
  </form>
</template>

<script>
import "./FormContainer.scss";

export default {
  data: () => ({
    error: true
  }),
  name: "FormContainer",
  props: {}
};
</script>
