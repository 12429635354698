<template>
  <FormContainer>
    <FormGroup
      :form="forms.CONTACT_FORM"
      :rows="8"
      placeholder="Popište problém*"
      name="contactMessage"
      v-model="contactMessage"
      :validate="[validations.noEmpty]"
    />
    <div class="mt-4">
      <Loader v-if="isActionFetching(actions.SEND_CONTACT_MSG)" />
      <Button
        v-else
        theme="primary"
        className="mr-2 button--wide"
        :actionHandler="() => sendContactForm()"
        isActive
      >
        Odeslat
      </Button>
      <Button
        theme="link"
        className="ml-5"
        :actionHandler="() => $router.push('/order-overview')"
      >
        Zpět
      </Button>
      <TextBlock
        v-if="isActionError(actions.SEND_CONTACT_MSG)"
        type="span"
        className="mt-3"
        errorMessage
      >
        Při odesílání formuláře došlo k chybě.
      </TextBlock>
    </div>
  </FormContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TextBlock from "@/components/TextBlock";
import FormGroup from "@/components/FormGroup";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import FormContainer from "@/components/FormContainer";

import { noEmpty } from "@/helpers/validations";
import { CONTACT_FORM } from "@/constants/forms";
import { SEND_CONTACT_MSG, SUBMIT_FORM } from "@/constants/actions";
import { FORM_MODULE, API_MODULE, USER_MODULE } from "@/constants/modules";
import mapFields from "@/helpers/mapFields";

export default {
  name: "ContactForm",
  data: () => ({
    forms: { CONTACT_FORM },
    validations: { noEmpty },
    actions: { SEND_CONTACT_MSG }
  }),
  components: {
    FormContainer,
    TextBlock,
    FormGroup,
    Button,
    Loader
  },
  methods: {
    ...mapActions(FORM_MODULE, [SUBMIT_FORM]),
    ...mapActions(USER_MODULE, [SEND_CONTACT_MSG]),

    sendContactForm() {
      this.submitForm({
        form: CONTACT_FORM,
        action: ({ contactMessage }) => this[SEND_CONTACT_MSG](contactMessage)
      });
    }
  },
  computed: {
    ...mapGetters(API_MODULE, ["isActionFetching", "isActionError"]),
    ...mapFields({
      form: CONTACT_FORM,
      fields: ["contactMessage"]
    })
  }
};
</script>
