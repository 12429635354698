<template>
  <ol v-if="ordered" class="list list--ordered">
    <slot />
  </ol>
  <ul v-else class="list">
    <slot />
  </ul>
</template>

<script>
import "./List.scss";

export default {
  name: "List",
  props: {
    ordered: {
      type: Boolean,
      default: false
    }
  }
};
</script>
