<template>
  <b-row class="align-items-center animated" :class="!last && 'mb-5'">
    <b-col
      sm="6"
      :class="number % 2 !== 0 ? 'order-2 order-md-1' : 'order-2 order-md-2'"
    >
      <img class="img-fluid" :src="image" :alt="title" />
    </b-col>
    <b-col
      sm="6"
      :class="number % 2 !== 0 ? 'order-1 order-md-2' : 'order-1 order-md-1'"
    >
      <TextBlock type="span" class="hiw__number">{{ number }}.</TextBlock>
      <Title :level="3" className="hiw__title mb-3">{{ title }}</Title>
      <TextBlock type="p" className="hiw__subtitle">
        {{ text }}
      </TextBlock>
    </b-col>
  </b-row>
</template>

<script>
import "./HowItWorks.scss";
import TextBlock from "@/components/TextBlock";
import Title from "@/components/Title";

export default {
  name: "HowItWorks",
  components: {
    TextBlock,
    Title
  },
  props: {
    title: String,
    text: String,
    image: String,
    number: String,
    last: Boolean
  },
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    this.observer.observe(this.$el);
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 0.6
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        this.observer.unobserve(target);
        this.$el.classList.add("fadeIn");
      });
    }
  }
};
</script>
