<template>
  <p v-if="type === 'p'" :class="cssClass">
    <slot />
  </p>
  <span v-else-if="type === 'span'" :class="cssClass">
    <slot />
  </span>
</template>

<script>
import "./TextBlock.scss";

export default {
  name: "TextBlock",
  data: ({ className, color, errorMessage, successMessage, size }) => ({
    cssClass: [
      "text-block",
      className ? className : "",
      color ? `color--theme-${color}` : "",
      errorMessage ? "text-block--error" : "",
      successMessage ? "text-block--success" : "",
      size ? `text-block--${size}` : ""
    ]
  }),
  props: {
    type: String,
    color: String,
    errorMessage: Boolean,
    size: String,
    successMessage: Boolean,
    className: String
  }
};
</script>
