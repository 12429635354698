<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="8" offset-md="2">
          <Title :level="1">Kontaktovat podporu</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset-md="2" lg="4">
          <ContactForm />
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import ContactForm from "@/components/ContactForm";

export default {
  name: "ContactSupport",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    ContactForm
  }
};
</script>
