<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="7" offset-md="2">
          <Title :level="1">Úprava uživatele</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
        </b-col>
        <b-col md="6" lg="4" offset-md="2">
          <PersonalDataForm ref="personalDataForm" />
        </b-col>
      </b-row>
    </Section>
    <ActionStrip
      :actionHandler="() => $refs.personalDataForm.submitPersonalDataForm()"
      :disabled="!isFormValid(forms.PERSONAL_DATA_FORM)"
      >Uložit úpravy</ActionStrip
    >
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import ActionStrip from "@/components/ActionStrip";
import PersonalDataForm from "@/components/PersonalDataForm";
import { mapGetters } from "vuex";
import { FORM_MODULE, USER_MODULE } from "@/constants/modules";
import { PERSONAL_DATA_FORM } from "@/constants/forms";

export default {
  name: "OrderPersonalData",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    ActionStrip,
    PersonalDataForm
  },
  data: () => ({
    forms: { PERSONAL_DATA_FORM }
  }),
  computed: {
    ...mapGetters(FORM_MODULE, ["isFormValid"]),
    ...mapGetters(USER_MODULE, ["userPersonalData"])
  }
};
</script>
