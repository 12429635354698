<template>
  <li class="list__item">
    <slot />
  </li>
</template>

<script>
import "./List.scss";

export default {
  name: "ListItem"
};
</script>
