<template>
  <div
    :class="[
      'notification-box',
      type ? `notification-box--${type}` : null,
      className ? className : null
    ]"
  >
    <img
      v-if="type === 'warning'"
      class="notification-box__icon"
      src="@/assets/images/warning-triangle.svg"
      alt="warning"
    />
    <slot />
  </div>
</template>

<script>
import "./NotificationBox.scss";

export default {
  data() {
    return {};
  },
  name: "NotificationBox",
  props: {
    type: String,
    className: String
  },
  methods: {}
};
</script>
