<template>
  <div
    :class="[
      'page',
      withGradient ? 'page--with-gradient' : '',
      withBottomStrip ? 'page--with-bottom-strip' : ''
    ]"
  >
    <Header
      v-if="!withoutHeader"
      :withAfterLoginButton="withAfterLoginButton"
    />
    <slot />
    <Footer v-if="!withoutFooter" />
    <CookieBar v-if="!isCookieAgreed" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import CookieBar from "@/components/CookieBar";
import { USER_MODULE } from "@/constants/modules";

import "./PageLayout.scss";

export default {
  name: "PageLayout",
  components: {
    Header,
    Footer,
    CookieBar
  },
  props: {
    withoutHeader: Boolean,
    withoutFooter: Boolean,
    withAfterLoginButton: Boolean,
    withGradient: Boolean,
    withBottomStrip: Boolean
  },
  computed: {
    ...mapGetters(USER_MODULE, ["isCookieAgreed"])
  }
};
</script>
