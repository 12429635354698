<template>
  <PageLayout :withAfterLoginButton="true" withGradient>
    <Section :offset="['top-half', 'without-bottom']">
      <b-row>
        <b-col md="10">
          <Title :level="1">Chytrá hlasová schránka</Title>
          <TextBlock type="span" className="color--theme-white-fade">
            která vám přepíše vzkaz do SMS
          </TextBlock>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" lg="10">
          <TagGroup
            :data="[
              'Ušetří desítky minut denně',
              'Voláte zpět a víte, o co jde',
              'Zapnete, jen když potřebujete'
            ]"
            className="mt-5"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" lg="7">
          <TextBlock type="p" size="small" className="pr-5 mt-3">
            Chytrá hlasovka zjistí, kdo volá, a vzkaz vám pošle do SMS nebo
            e-mailu. Získáte klid na práci nebo odpočinek a přitom víte, co se
            děje.
          </TextBlock>
        </b-col>
      </b-row>
      <PlatformCarousel />
      <b-row class="mt-5 mt-md-0">
        <b-col cols="6" md="5" lg="5" class="mt-md-5 mt-lg-0">
          <vue-typed-js
            :strings="['Schůzky, čas&nbsp;na práci, dovolená...']"
            :startDelay="1000"
          >
            <Title :level="2"><span class="typing"></span></Title>
          </vue-typed-js>
        </b-col>
        <b-col cols="6" md="7" lg="6">
          <FadeSentences sentence="bez zvonícího telefonu" :count="7" />
        </b-col>
      </b-row>
    </Section>

    <Section theme="white" id="jak-to-funguje">
      <b-row>
        <b-col>
          <Title :level="2" className="mb-5">Jak to funguje</Title>
        </b-col>
      </b-row>
      <HowItWorks
        number="1"
        title="Schránku jednoduše nastavíte"
        text="Na telefonu si vyberete, zda do schránky chcete přesměrovat automaticky všechny hovory nebo třeba jen ty, které odmítnete. "
        :image="require('@/assets/images/hiw_1.png')"
      />
      <HowItWorks
        number="2"
        title="Zvolíte si uvítací zprávu"
        text="V uživatelském účtu zadáte zprávu, která uvítá volajícího, a my ji převedeme do plynulé řeči."
        :image="require('@/assets/images/hiw_2.png')"
      />
      <HowItWorks
        number="3"
        :last="true"
        title="Vzkaz od volajícího vám přijde do SMS a e-mailu"
        text="Ihned víte, kdo vám volal a co potřeboval. Podle toho se rozhodnete, kdy zavoláte zpět. Ušetříte jeden hovor, protože před voláním zpět už víte to podstatné."
        :image="require('@/assets/images/hiw_3.png')"
      />
    </Section>

    <Section theme="white" id="recenze">
      <b-row>
        <b-col>
          <Title :level="2" className="mb-5">Recenze našich zákazníků</Title>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <CustomerReview
            title="Marketingový ředitel"
            text="Když jsem na dovolené, potřebuji vypustit. Na druhou stranu v urgentních případech potřebuji být na příjmu. TooBizi mi přepošle důležité vzkazy do SMS."
            name="Jan"
            position="Marketing manager"
            :image="require('@/assets/images/review_1.png')"
          />
        </b-col>
        <b-col sm="4">
          <CustomerReview
            title="Řemeslník"
            text="V průběhu dne jsem většinou na zakázce a mám plné ruce. Nemůžu tak zvednout telefon zákazníkům. TooBizi asistentka mi vzkazy přepíše a já se s klienty domluvím hned, když mám volnou chvíli."
            name="Tomáš"
            position="Hodinový manžel"
            :image="require('@/assets/images/review_2.png')"
            className="delay-1"
          />
        </b-col>
        <b-col sm="4">
          <CustomerReview
            title="Barbershop"
            text="Jsme menší barbershop, nemáme recepční. TooBizi nám ji nahrazuje. Zákazníkům se věnuji vždy na 100 %. Nemám čas zvedat telefon během stříhání. "
            name="Michael"
            position="Monkey Barber"
            :image="require('@/assets/images/review_3.png')"
            className="delay-2"
          />
        </b-col>
      </b-row>
    </Section>
    <Section theme="black" id="cenik">
      <b-row>
        <b-col class="text-center">
          <Title :level="2" className="mb-5">Kolik stojí klid na práci</Title>
          <TextBlock
            type="p"
            className="pricing__subtitle color--theme-orange mb-5"
          >
            Do 31. 3. získáte startovací
            <span class="color--theme-white"> slevu 20 %</span> na všechny naše
            balíčky.
          </TextBlock>
          <Countdown
            finalDate="Mar 31, 2022 00:00:00"
            class="mb-5"
            title="Sleva platí ještě:"
          />
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col
          lg="4"
          v-for="(tariff, index) in availableTariffs"
          :key="tariff.id"
        >
          <PricingGroup
            :highlighted="tariff.data.highlighted"
            :className="'delay-' + index * 2"
            :salePrice="
              tariff.data.salePrice.iv +
                ' ' +
                tariff.data.currency.cs +
                ' / měsíc'
            "
            :sale="tariff.data.label.cs"
            :saleColor="tariff.data.color.iv"
            :price="
              tariff.data.price.cs + ' ' + tariff.data.currency.cs + ' / měsíc'
            "
            :title="tariff.data.name"
            :subtitle="tariff.data.description.cs"
            :benefits="tariff.data.functions.cs"
          />
        </b-col>
      </b-row>
    </Section>
    <Section theme="white" :offset="['bottom-double']" id="demo">
      <b-row>
        <b-col lg="11" offset-lg="1">
          <Title :level="2" className="font-weight-bold">Poslechněte si</Title>
          <TextBlock type="span" className="text-block--subtitle">
            Nahrané hovory s chytrou hlasovou schránkou
          </TextBlock>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" lg="8" offset-lg="4">
          <div class="mt-md-5 mb-md-5">
            <AudioPlayer audioName="TooBizi_1.mp3" title="Osobní záležitosti" />
          </div>
          <div class="mb-md-5">
            <AudioPlayer audioName="TooBizi_2.mp3" title="Domluvení schůzky" />
          </div>
          <div class="mb-md-5">
            <AudioPlayer audioName="TooBizi_3.mp3" title="Obchodní jednání" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" lg="8" offset-lg="4">
          <Title :level="2" className="mt-5 font-weight-bold"
            >Vyzkoušejte to sami</Title
          >
          <TextBlock type="span" className="text-block--subtitle">
            Popovídejte si s chytrou hlasovou schránkou.
          </TextBlock>
          <div class="d-md-inline-block text-center">
            <Button
              theme="demo"
              className="mt-5 mb-3"
              :actionHandler="() => null"
              id="demo-button"
            >
              +420 228 229 136
            </Button>
            <TextBlock type="p" class="demo__description"
              >VYZKOUŠEJTE ZDARMA
            </TextBlock>
          </div>
        </b-col>
      </b-row>
      <img
        src="@/assets/images/orange-figure.svg"
        alt="figure"
        id="arrow"
        class="section__absolute-img section__absolute-img--figure"
      />
      <img
        src="@/assets/images/hand.png"
        alt="hand"
        id="hand"
        class="section__absolute-img section__absolute-img--hand"
      />
    </Section>

    <GdprStripe />
    <GdprDialog />
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Section from "@/components/Section";
import TagGroup from "@/components/TagGroup";
import AudioPlayer from "@/components/AudioPlayer";
import Button from "@/components/Button";
import Countdown from "@/components/Countdown";
import PlatformCarousel from "@/components/PlatformCarousel";
import HowItWorks from "@/components/HowItWorks";
import CustomerReview from "@/components/CustomerReview";
import PricingGroup from "@/components/PricingGroup";
import FadeSentences from "@/components/FadeSentences";
import TextBlock from "@/components/TextBlock";
import Title from "@/components/Title";
import GdprStripe from "@/components/GdprStripe";
import GdprDialog from "@/components/GdprDialog";

import openExternalUrl from "@/helpers/openExternalUrl";
import { mapGetters } from "vuex";
import { ORDER_MODULE } from "@/constants/modules";

export default {
  name: "Home",
  data: () => ({ openExternalUrl, observer: null }),
  components: {
    PageLayout,
    Section,
    TagGroup,
    AudioPlayer,
    Countdown,
    Button,
    PlatformCarousel,
    HowItWorks,
    CustomerReview,
    PricingGroup,
    FadeSentences,
    TextBlock,
    Title,
    GdprStripe,
    GdprDialog
  },
  mounted() {
    let target = document.querySelector("#demo-button");
    this.observer.observe(target);
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      threshold: 0.5
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  computed: {
    ...mapGetters(ORDER_MODULE, ["availableTariffs"])
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        this.observer.unobserve(target);
        let arrow = document.querySelector("#arrow");
        arrow.classList.add("animate");
      });
    }
  }
};
</script>
