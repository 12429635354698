<template>
  <div
    class="pricing-group animated"
    v-bind:class="[highlighted ? 'pricing-group--highlighted' : '', className]"
  >
    <TextBlock
      type="p"
      v-if="sale"
      class="pricing-group__sale"
      :class="saleColor && 'pricing-group__sale--' + saleColor"
      >{{ sale }}
    </TextBlock>
    <b-row>
      <b-col>
        <Title :level="2" className="pricing-group__title mb-3">{{
          title
        }}</Title>
        <TextBlock type="p" className="pricing-group__subtitle mb-5">
          {{ subtitle }}
        </TextBlock>
        <ul class="pricing-group__benefits  mb-5">
          <li v-for="benefit in benefits" :key="benefit.index">
            {{ benefit.name }}
          </li>
        </ul>

        <TextBlock
          type="p"
          class="pricing-group__price"
          v-bind:class="[
            price
              ? 'pricing-group__price--old'
              : 'pricing-group__price--original'
          ]"
          >{{ salePrice }}
        </TextBlock>
        <TextBlock
          type="p"
          v-if="price"
          class="pricing-group__price pricing-group__price--original"
          >{{ price }}
        </TextBlock>

        <Button
          theme="pricing"
          className="mt-2"
          v-if="!order"
          :actionHandler="
            () => {
              this.gtmTrack();
              this.$router.push('/login');
            }
          "
          >Vyzkoušet
        </Button>

        <Button
          theme="pricing"
          className="mt-2"
          :actionHandler="selectAction"
          v-if="order"
          >Vybrat
        </Button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "./PricingGroup.scss";
import TextBlock from "@/components/TextBlock";
import Title from "@/components/Title";
import Button from "@/components/Button";

export default {
  name: "PricingGroup",
  props: {
    title: String,
    subtitle: String,
    price: String,
    salePrice: String,
    sale: String,
    saleColor: String,
    benefits: Array,
    highlighted: Boolean,
    className: String,
    order: Boolean,
    selectAction: Function
  },
  components: {
    TextBlock,
    Button,
    Title
  },
  data: () => ({
    observer: null
  }),
  mounted() {
    this.observer.observe(this.$el);
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 0.3
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    gtmTrack() {
      if (this.$gtm.enabled() && !this.order) {
        try {
          window.dataLayer?.push({
            event: "buyClicked"
          });
        } catch (e) {
          return;
        }
      }
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        this.observer.unobserve(target);
        this.$el.classList.add("zoomIn");
      });
    }
  }
};
</script>
