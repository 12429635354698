<template>
  <div v-if="cardOverview.cardNumberMasked">
    <TextBlock type="span" color="green" className="text--semibold">
      {{ getCardName() }}
    </TextBlock>
    <TextBlock
      type="span"
      color="orange"
      className="d-block mb-2 text--semibold"
    >
      {{ cardOverview.cardNumberMasked }}
    </TextBlock>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ORDER_MODULE } from "@/constants/modules";
import TextBlock from "@/components/TextBlock";

export default {
  name: "CardSummary",
  components: {
    TextBlock
  },
  methods: {
    getCardName() {
      const { bankName, cardBrand, cardType } = this.cardOverview;
      return `${cardType} ${cardBrand} ${bankName}`;
    }
  },
  computed: {
    ...mapGetters(ORDER_MODULE, ["cardOverview"])
  }
};
</script>
