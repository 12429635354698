<template>
  <FormContainer>
    <FormGroup
      :form="forms.LOGIN_FORM"
      v-model="login"
      name="login"
      placeholder="Zadejte email"
      :validate="[validations.noEmpty, validations.email]"
    />
    <Loader v-if="isActionFetching(actions.SEND_VERIFY_EMAIL)" />
    <Button
      v-else
      theme="link"
      :actionHandler="
        () => {
          this.gtmTrack();
          this[actions.SUBMIT_FORM]({
            form: forms.LOGIN_FORM,
            action: ({ login }) => this[actions.SEND_VERIFY_EMAIL](login)
          });
        }
      "
    >
      Přihlásit se
    </Button>
    <TextBlock
      v-if="isActionError(actions.SEND_VERIFY_EMAIL)"
      type="span"
      className="mt-3"
      errorMessage
    >
      Při odesílání formuláře došlo k chybě.
    </TextBlock>
  </FormContainer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import mapFields from "@/helpers/mapFields";
import { SEND_VERIFY_EMAIL, SUBMIT_FORM } from "@/constants/actions";
import { LOGIN_FORM } from "@/constants/forms";
import { API_MODULE, FORM_MODULE, USER_MODULE } from "@/constants/modules";
import { noEmpty, email } from "@/helpers/validations";

import FormContainer from "@/components/FormContainer";
import FormGroup from "@/components/FormGroup";
import Button from "@/components/Button";
import TextBlock from "@/components/TextBlock";
import Loader from "@/components/Loader";

export default {
  name: "LoginForm",
  components: {
    FormContainer,
    FormGroup,
    TextBlock,
    Loader,
    Button
  },
  data: () => ({
    validations: { noEmpty, email },
    actions: { SEND_VERIFY_EMAIL, SUBMIT_FORM },
    forms: { LOGIN_FORM }
  }),
  computed: {
    ...mapGetters(API_MODULE, ["isActionFetching", "isActionError"]),
    ...mapFields({
      form: LOGIN_FORM,
      fields: ["login"]
    }),
    ...mapGetters(FORM_MODULE, ["getFormValues"])
  },
  methods: {
    gtmTrack: function() {
      if (this.$gtm.enabled()) {
        try {
          window.dataLayer?.push({
            event: "registerForm"
          });
        } catch (e) {
          return;
        }
      }
    },
    ...mapActions(FORM_MODULE, [SUBMIT_FORM]),
    ...mapActions(USER_MODULE, [SEND_VERIFY_EMAIL])
  }
};
</script>
