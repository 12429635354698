<template>
  <section
    :class="[
      'section',
      theme ? `section--${theme}` : '',
      offset ? [...offset.map(offsetType => `section--${offsetType}`)] : '',
      isRelative ? `section--relative` : ''
    ]"
  >
    <b-container>
      <slot></slot>
    </b-container>
  </section>
</template>

<script>
import "./Section.scss";

export default {
  name: "Section",
  props: {
    theme: String,
    offset: Array,
    isRelative: Boolean
  }
};
</script>
