<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="8" offset-md="2">
          <Title :level="1" className="mb-5">Pokračujte do emailu</Title>
          <TextBlock type="p" className="pt-5 pb-5"
            >Do vaší emailové schránky jsme zaslali ověřovací odkaz. Po kliknutí
            na tento odkaz bude možné v objednávce pokračovat.</TextBlock
          >
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";

export default {
  name: "VerifySend",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock
  }
};
</script>
