import createRequest from "@/helpers/createRequest";
import router from "@/router";
import {
  EDIT_SCENARIO,
  GET_SCENARIO_OVERVIEW,
  VERIFY_PROMO_CODE,
  REMOVE_SCENARIO,
  GET_PAYMENT_DATA,
  GET_PAYMENTS_OVERVIEW,
  VERIFY_RECEIVED_PAYMENT,
  GET_PAYMENT_PDF,
  USER_LOGOUT,
  CHANGE_PAYMENT,
  SELECT_TARIFF,
  GET_AVAILABLE_TARIFFS
} from "@/constants/actions";
import { USER_MODULE } from "@/constants/modules";

const API_URL = process.env.VUE_APP_API_URL;

const actions = {
  [EDIT_SCENARIO]: (
    { dispatch, commit, getters },
    { id, notification, scenarioData, phoneNumber }
  ) =>
    createRequest({
      action: EDIT_SCENARIO,
      method: "POST",
      url: `${API_URL}/Order/Edit`,
      withAutorization: true,
      data: {
        scenarioId: id ? id : "",
        notification,
        data: scenarioData,
        phoneNumber,
        tarif: getters["currentTariffId"]
      },
      success: ({ scenarioId }) => {
        const hasOrderScenario = getters["hasOrderScenario"];

        dispatch(GET_SCENARIO_OVERVIEW).then(() => {
          commit("setCurrentScenario", scenarioId);

          if (hasOrderScenario) {
            getters["isScenarioActive"](scenarioId)
              ? router.push("/order-overview")
              : router.push("/order-payment");
          } else {
            commit("setScenarios", [{ scenarioId }]);
            router.push("/order-tariff");
          }
        });
      }
    }),

  [SELECT_TARIFF]: ({ commit }, { tariffId }) => {
    commit("setCurrentTariffId", tariffId);
    router.push("/order-scenario");
  },

  [GET_AVAILABLE_TARIFFS]: ({ commit }) =>
    createRequest({
      action: GET_AVAILABLE_TARIFFS,
      method: "GET",
      url: `${API_URL}/Home/Tarifs`,
      success: tariffs => {
        commit("setAvailableTariffs", tariffs);
      }
    }),

  [GET_SCENARIO_OVERVIEW]: ({ commit }, redirectUrl = null) =>
    createRequest({
      action: GET_SCENARIO_OVERVIEW,
      method: "GET",
      url: `${API_URL}/Order/Overview`,
      withAutorization: true,
      success: ({ scenarios }) => {
        commit("setScenarios", scenarios);
        redirectUrl ? router.push(redirectUrl) : null;
      }
    }),

  [VERIFY_PROMO_CODE]: ({ dispatch, commit }, { code, currentScenario }) =>
    createRequest({
      action: VERIFY_PROMO_CODE,
      method: "POST",
      url: `${API_URL}/Order/PromoCode`,
      withAutorization: true,
      data: { code },
      success: () => {
        dispatch(GET_SCENARIO_OVERVIEW);
        commit("setCurrentScenario", currentScenario.scenarioId);
      }
    }),

  [REMOVE_SCENARIO]: ({ dispatch, getters }) => {
    createRequest({
      action: REMOVE_SCENARIO,
      method: "POST",
      url: `${API_URL}/Order/DisconnectScenario`,
      withAutorization: true,
      data: {
        scenarioId: getters["currentScenario"].scenarioId
      },
      success: () => {
        dispatch(`${USER_MODULE}/${USER_LOGOUT}`, {}, { root: true });
        router.push("/scenario-removed");
      }
    });
  },

  [GET_PAYMENT_DATA]: ({ commit }) => {
    createRequest({
      action: GET_PAYMENT_DATA,
      method: "GET",
      url: `${API_URL}/Order/GetPaymentData`,
      withAutorization: true,
      success: data => {
        const cardPaymentMethod = data.find(
          // paymentType => paymentType.method.name === 'Platba kartou'
          paymentType => paymentType.method.id === 21
        );

        const bankPaymentMethod = data.find(
          paymentType => paymentType.method.id === 18
        );

        if (cardPaymentMethod) {
          commit("setPaymentUrl", cardPaymentMethod.url);
        } else {
          commit("setPaymentUrl", bankPaymentMethod.url);
        }
      },
      error: () => router.push("/order-overview")
    });
  },

  [GET_PAYMENTS_OVERVIEW]: ({ commit }) => {
    createRequest({
      action: GET_PAYMENTS_OVERVIEW,
      method: "GET",
      url: `${API_URL}/User/PaymentsOverview`,
      withAutorization: true,
      success: ({ payments, cardDetail }) => {
        const validPayments = payments.filter(
          payment => payment.pricePaid !== null
        );
        commit("setPaymentsOverview", validPayments);
        commit("setCardOverview", cardDetail);
      }
    });
  },

  [VERIFY_RECEIVED_PAYMENT]: ({ dispatch }, data) => {
    createRequest({
      action: VERIFY_RECEIVED_PAYMENT,
      method: "POST",
      url: `${API_URL}/Order/ReceivePayment`,
      data,
      withAutorization: true,
      success: () => dispatch(GET_SCENARIO_OVERVIEW, "/order-overview")
    });
  },

  [GET_PAYMENT_PDF]: (_context, paymentId) => {
    createRequest({
      action: GET_PAYMENT_PDF,
      method: "POST",
      url: `${API_URL}/User/PaymentPdf`,
      data: { paymentId },
      withAutorization: true,
      responseType: "blob",
      success: data => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `invoice-${paymentId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  },

  [CHANGE_PAYMENT]: ({ commit }, { enable }) => {
    createRequest({
      action: CHANGE_PAYMENT,
      method: "POST",
      url: `${API_URL}/User/EditReccurentPayment`,
      data: { enable },
      withAutorization: true,
      success: () => commit("changeReccurentPayment", enable)
    });
  }
};

export default actions;
