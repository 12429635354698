<template>
  <div>
    <b-row>
      <b-col md="8" offset-md="2" class="mt-5 mb-2">
        <TextBlock type="span" color="green" className="d-block text--semibold"
          >Přehled plateb</TextBlock
        >
      </b-col>
    </b-row>
    <b-row v-if="isActionFetching(actions.GET_PAYMENTS_OVERVIEW)">
      <b-col md="4" offset-md="2">
        <Loader />
      </b-col>
    </b-row>
    <b-row
      v-else-if="paymentsOverview.length > 0"
      v-for="{ paymentId, timestamp, pricePaid, currency } in paymentsOverview"
      :key="paymentId"
    >
      <b-col cols="8" md="4" offset-md="2">
        <TextBlock type="span" class="text-block--medium text-block--semibold">
          {{ `${formatDate(timestamp)}, ${pricePaid} ${currency}` }}
        </TextBlock>
      </b-col>
      <b-col cols="4" md="2">
        <Button theme="link" :actionHandler="() => getPaymentPdf(paymentId)">
          Faktura
        </Button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="10" offset-md="2" class="mb-3">
        <TextBlock
          type="span"
          color="green"
          class="text-block--medium text-block--semibold"
        >
          Nebyly nalezeny žádné platby.
        </TextBlock>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import { GET_PAYMENTS_OVERVIEW, GET_PAYMENT_PDF } from "@/constants/actions";
import { API_MODULE, ORDER_MODULE } from "@/constants/modules";
import openExternalUrl from "@/helpers/openExternalUrl";
import formatDate from "@/helpers/formatDate";

export default {
  name: "PaymentsSummary",
  data: () => ({
    actions: { GET_PAYMENTS_OVERVIEW, GET_PAYMENT_PDF },
    openExternalUrl,
    formatDate
  }),
  components: {
    TextBlock,
    Button,
    Loader
  },
  methods: {
    ...mapActions(ORDER_MODULE, [GET_PAYMENT_PDF, GET_PAYMENTS_OVERVIEW])
  },
  computed: {
    ...mapGetters(ORDER_MODULE, ["paymentsOverview"]),
    ...mapGetters(API_MODULE, ["isActionFetching", "isActionError"])
  },
  mounted() {
    this.getPaymentsOverview();
  }
};
</script>
