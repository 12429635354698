<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="7" offset-md="2">
          <Title :level="1">Členství</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
        </b-col>
      </b-row>
      <OrderAdministration />
      <b-row class="mt-5">
        <b-col md="7" offset-md="2">
          <Title :level="5" className="mb-3 mt-5">
            Nastavení přesměrování u operátora:
          </Title>
          <List>
            <ListItem>Pokud mám vypnutý telefon</ListItem>
            <ListItem>Nezvednu telefon do určitého času</ListItem>
            <ListItem>Zamítnu hovor</ListItem>
          </List>
          <Button
            theme="secondary"
            className="mr-4"
            :actionHandler="
              () =>
                openExternalUrl(
                  '/faq#jak-nastavit-presmerovani-hovoru-vodafone'
                )
            "
          >
            Vodafone
          </Button>
          <Button
            theme="secondary"
            className="mr-4"
            :actionHandler="
              () =>
                openExternalUrl(
                  '/faq#jak-nastavit-presmerovani-hovoru-t-mobile'
                )
            "
          >
            T-mobile
          </Button>
          <Button
            theme="secondary"
            :actionHandler="
              () => openExternalUrl('/faq#jak-nastavit-presmerovani-hovoru-o2')
            "
          >
            O2
          </Button>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col md="7" offset-md="2">
          <Title :level="5" className="mb-3 mt-5">
            Nastavení přesměrování v telefonu:
          </Title>
          <List>
            <ListItem>Přesměrování veškerých hovorů</ListItem>
          </List>
          <Button
            theme="secondary"
            className="mr-4"
            :actionHandler="
              () =>
                openExternalUrl(
                  '/faq#jak-nastavit-presmerovani-hovoru-na-iphone-ios'
                )
            "
          >
            Pro Iphone
          </Button>
          <Button
            theme="secondary"
            :actionHandler="
              () =>
                openExternalUrl(
                  '/faq#jak-nastavit-presmerovani-hovoru-na-android'
                )
            "
          >
            Pro Android
          </Button>
        </b-col>
      </b-row>
      <GdprButton />
      <ScenarioDeactivateDialog />
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import GdprButton from "@/components/GdprButton";
import ScenarioDeactivateDialog from "@/components/ScenarioDeactivateDialog";
import OrderAdministration from "@/components/OrderAdministration";
import { List, ListItem } from "@/components/List";

import formatDate from "@/helpers/formatDate";
import openExternalUrl from "@/helpers/openExternalUrl";
import formatPhoneNumber from "@/helpers/formatPhoneNumber";
import { SCENARIO_DEACTIVATE_DIALOG } from "@/constants/dialogs";

export default {
  name: "OrderOverview",
  data: () => ({
    formatDate,
    openExternalUrl,
    formatPhoneNumber,
    dialogs: { SCENARIO_DEACTIVATE_DIALOG }
  }),
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    Button,
    GdprButton,
    ScenarioDeactivateDialog,
    List,
    ListItem,
    OrderAdministration
  }
};
</script>
