<template>
  <article
    itemscope
    itemprop="mainEntity"
    itemtype="https://schema.org/Question"
    class="faq__item"
    :id="niceQuestion"
    :class="active && 'faq__item--active'"
  >
    <button class="faq__item__button" @click="toggle"></button>
    <Title :level="3" itemprop="name" @click.native="toggle"> {{ question }}</Title>
    <div
      class="faq__item__content"
      itemscope
      itemprop="acceptedAnswer"
      itemtype="https://schema.org/Answer"
    >
      <div itemprop="text">
        <VueSlideToggle :open="active" tag="section" :duration="500">
          <TextBlock type="p">
            <slot />
          </TextBlock>
        </VueSlideToggle>
      </div>
    </div>
  </article>
</template>

<script>
import "./FaqItem.scss";
import TextBlock from "@/components/TextBlock";
import Title from "@/components/Title";
import { VueSlideToggle } from "vue-slide-toggle";

export default {
  name: "FaqItem",
  props: {
    question: String
  },
  data() {
    return {
      active: false,
      niceQuestion: this.question
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    };
  },
  mounted: function() {
    if (
      this.$router.history.current.hash != "" &&
      this.$router.history.current.hash == "#" + this.niceQuestion
    ) {
      this.active = true;
    }
  },
  components: {
    TextBlock,
    Title,
    VueSlideToggle
  },
  methods: {
    toggle() {
      this.active = !this.active;
      if (this.active) {
        history.pushState(null, null, "#" + this.niceQuestion);
      }
    }
  }
};
</script>
