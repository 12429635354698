<template>
  <button
    @click="actionHandler"
    :class="[
      'button',
      theme ? `button--${theme}` : '',
      isActive ? 'button--active' : '',
      disabled ? 'button--disabled' : '',
      noAction ? 'button--no-action' : '',
      notReady ? 'button--not-ready' : '',
      className ? className : ''
    ]"
  >
    <img
      v-if="theme === 'icon-button' && icon"
      :src="require(`@/assets/images/${icon}.svg`)"
      alt="icon"
      class="mr-3"
    />
    <slot />
  </button>
</template>

<script>
import "./Button.scss";

export default {
  name: "Button",
  props: {
    theme: String,
    className: [String, Array],
    icon: String,
    actionHandler: Function,
    noAction: Boolean,
    isActive: Boolean,
    notReady: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
