import store from "@/store";
import { FORM_MODULE } from "@/constants/modules";

const mapFields = ({ form, fields }) => {
  const formController = {};

  fields.map(
    field =>
      (formController[field] = {
        get() {
          return store.state[FORM_MODULE][form].values[field];
        },
        set(value) {
          store.commit(`${FORM_MODULE}/setFormFieldValue`, {
            form,
            field,
            value
          });
        }
      })
  );
  return formController;
};

export default mapFields;
