var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageLayout',{attrs:{"withoutFooter":""}},[_c('Section',[_c('b-row',[_c('b-col',{attrs:{"md":"10","offset-md":"2","lg":"8"}},[_c('Title',{attrs:{"level":1}},[_vm._v("Objednávka")]),_c('TextBlock',{staticClass:"text-block--heading-perex",attrs:{"type":"span"}},[_vm._v(" chytré hlasové asistentky ")]),_c('TextBlock',{attrs:{"type":"span","className":"d-block"}},[_vm._v("Zprávy budete dostávat na email")]),_c('TextBlock',{attrs:{"type":"span","color":"green","className":"d-block mb-4"}},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")]),_c('TextBlock',{attrs:{"type":"span","className":"d-block mb-4 mb-md-3"}},[_vm._v(" Cena "+_vm._s(_vm.currentScenario ? _vm.currentScenario.price : "")+" Kč "+_vm._s(this.currentTariff.data.duration === 1 ? "ročně" : "měsíčně")+" ")]),_c('Button',{attrs:{"theme":"link","className":"mb-5 mb-md-4","actionHandler":function () { return this$1.$router.push(
                ("/edit-scenario/" + (_vm.currentScenario.scenarioId))
              ); }}},[_vm._v("Upravit scénář")])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-5 mb-5",attrs:{"md":"8","offset-md":"2","lg":"6"}},[_c('NotificationBox',{attrs:{"type":"info"}},[_vm._v(" Kliknutím na tlačítko Zaplatit souhlasíte s pravidelnou platbou, kterou vám budeme účtovat jednou "+_vm._s(this.currentTariff.data.duration === 1 ? "ročně" : "měsíčně")+" ke dni Vaší registrace. Službu TooBizi můžete kdykoliv zrušit. ")])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-5 mt-md-0",attrs:{"md":"6","lg":"4","offset-md":"2"}},[_c('FormGroup',{attrs:{"form":_vm.forms.PAYMENT_FORM,"name":"promocode","placeholder":"Promocode","className":"mb-0"},model:{value:(_vm.promocode),callback:function ($$v) {_vm.promocode=$$v},expression:"promocode"}}),(_vm.isActionFetching(_vm.actions.VERIFY_PROMO_CODE))?_c('Loader'):(_vm.isActionSuccess(_vm.actions.VERIFY_PROMO_CODE))?_c('TextBlock',{attrs:{"type":"span","className":"mt-2","successMessage":""}},[_vm._v(" Promocode úspěšně ověřen ")]):_c('Button',{attrs:{"theme":"link","actionHandler":function () { return _vm.verifyPromoCode({ code: _vm.promocode, currentScenario: _vm.currentScenario }); }}},[_vm._v(" Použít ")]),(_vm.isActionError(_vm.actions.VERIFY_PROMO_CODE))?_c('TextBlock',{attrs:{"type":"span","className":"mt-2","errorMessage":""}},[_vm._v(" Promocode se nepodařilo ověřit ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-5",attrs:{"md":"8","offset-md":"2"}},[_c('FormGroup',{attrs:{"form":_vm.forms.PAYMENT_FORM,"name":"conditionsAgreement","label":"Souhlasím se","labelLink":{
            label: 'všeobecnými obchodními podmínkami',
            url: require('@/assets/pdf/tb_obchodni_podminky.pdf')
          },"type":"checkbox","className":"mb-0","validate":[_vm.validations.checked]},model:{value:(_vm.conditionsAgreement),callback:function ($$v) {_vm.conditionsAgreement=$$v},expression:"conditionsAgreement"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"8","offset-md":"2"}},[_c('FormGroup',{attrs:{"form":_vm.forms.PAYMENT_FORM,"name":"paymentAgreement","label":"Souhlasím s","labelLink":{
            label: 'pravidelnou měsíční platbou',
            action: function () { return _vm.toggleDialog(_vm.dialogs.PAYMENT_AGREEMENT_DIALOG); }
          },"type":"checkbox","className":"mb-0","validate":[_vm.validations.checked]},model:{value:(_vm.paymentAgreement),callback:function ($$v) {_vm.paymentAgreement=$$v},expression:"paymentAgreement"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"8","offset-md":"2"}},[_c('FormGroup',{attrs:{"form":_vm.forms.PAYMENT_FORM,"name":"gdprAgreement","label":"Souhlasím se","labelLink":{
            label: 'zpracováním osobních údajů',
            url: require('@/assets/pdf/tb_osobni_udaje.pdf')
          },"type":"checkbox","validate":[_vm.validations.checked]},model:{value:(_vm.gdprAgreement),callback:function ($$v) {_vm.gdprAgreement=$$v},expression:"gdprAgreement"}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"offset-md":"2"}},[_c('img',{staticClass:"mr-3 mb-3 mb-sm-0",attrs:{"src":require("@/assets/images/the-pay-rounded.svg"),"alt":"gopay"}}),_c('img',{staticClass:"mr-3 mb-3 mb-sm-0",attrs:{"src":require("@/assets/images/mastercard.svg"),"alt":"mastercard"}}),_c('img',{staticClass:"mr-3 mb-3 mb-sm-0",attrs:{"src":require("@/assets/images/visa.svg"),"alt":"visa"}})])],1),_c('GdprButton')],1),_c('ActionStrip',{attrs:{"actionHandler":function () { return _vm.redirectToPayment(); },"disabled":!_vm.isFormValid(_vm.forms.PAYMENT_FORM)}},[_vm._v(" "+_vm._s(_vm.getActivateMessage())+" ")]),_c('PaymentAgreementDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }