<template>
  <PageLayout :withAfterLoginButton="true" withGradient>
    <Section :offset="['top-half', 'without-bottom']" class="faq">
      <b-row>
        <b-col md="10">
          <Title :level="1" class="mb-5">FAQ</Title>
          <div class="mb-5">
            <Title :level="2" class="mb-3"
              >Jak nastavit přesměrování na telefonu:</Title
            >
            <FaqItem question="Jak nastavit přesměrování hovorů na Android">
              <Title :level="4" class="mb-3  mt-5 color--theme-green"
                >Samsung, Huawei, Xiaomi:</Title
              >
              <ol>
                <li>
                  Na Domovské obrazovce stiskněte ikonu Telefonu:
                  <div>
                    <img
                      src="@/assets/images/android1.png"
                      alt="Na Domovské obrazovce stiskněte ikonu Telefonu:"
                    />
                  </div>
                </li>
                <li>
                  Následně stiskněte ikonu tří teček v pravém horním rohu. Tím
                  se dostanete do nabídky, kde vyberte možnost Nastavení.
                  <div>
                    <img
                      src="@/assets/images/android2.png"
                      alt="Následně stiskněte ikonu tří teček v pravém horním rohu. Tím
                  se dostanete do nabídky, kde vyberte možnost Nastavení."
                    />
                  </div>
                </li>
                <li>
                  V Nastavení zvolte položku Přesměrování hovorů:
                  <div>
                    <img
                      src="@/assets/images/android3.png"
                      alt="  V Nastavení zvolte položku Přesměrování hovorů:"
                    />
                  </div>
                </li>
                <li>
                  Zde máte možnost nastavit, zda chcete přesměrovat všechny
                  hovory vždy, nebo pouze v případě, kdy budete mít obsazeno
                  nebo když budete nedostupní.
                  <div>
                    <img
                      src="@/assets/images/android4.png"
                      alt="  Zde máte možnost nastavit, zda chcete přesměrovat všechny
                  hovory vždy, nebo pouze v případě, kdy budete mít obsazeno
                  nebo když budete nedostupní."
                    />
                  </div>
                </li>
                <li>
                  Zvolte některou z možností a následně zadejte číslo vašeho
                  asistenta. Potom potvrďte stisknutím tlačítka Povolit.
                  <div>
                    <img
                      src="@/assets/images/android5.png"
                      alt="Zvolte některou z možností a následně zadejte číslo vašeho
                  asistenta. Potom potvrďte stisknutím tlačítka Povolit."
                    />
                  </div>
                </li>
                <li>
                  Tím je přesměrování nastaveno. Kdokoli vám bude volat, bude
                  automaticky přepojen na vašeho asistenta.
                </li>
              </ol>
            </FaqItem>
            <FaqItem question="Jak nastavit přesměrování hovorů na Iphone IOS">
              <ol>
                <li>
                  Na Domovské obrazovce stiskněte ikonu Nastavení:
                  <img
                    src="@/assets/images/ios0.png"
                    alt="Na Domovské obrazovce stiskněte ikonu Nastavení:"
                  />
                </li>
                <li>
                  V Nastavení sjeďte dolů a z nabídky vyberte položku Telefon:
                  <img
                    src="@/assets/images/ios1.png"
                    alt="V Nastavení sjeďte dolů a z nabídky vyberte položku Telefon:"
                  />
                </li>
                <li>
                  Zde zvolte možnost Přesměrování hovoru:
                  <img
                    src="@/assets/images/ios2.png"
                    alt="Zde zvolte možnost Přesměrování hovoru:"
                  />
                </li>
                <li>
                  Aktivujte přesměrování:
                  <img
                    src="@/assets/images/ios3.png"
                    alt="Zde zvolte možnost Přesměrování hovoru:"
                  />
                </li>
                <li>
                  Níže zadejte číslo vašeho asistenta (uložení může trvat 3
                  vteřiny), na které se mají hovory přesměrovat.
                  <img
                    src="@/assets/images/ios4.png"
                    alt="Níže zadejte číslo vašeho asistenta (uložení může trvat 3
                  vteřiny), na které se mají hovory přesměrovat."
                  />
                </li>
                <li>
                  Tím je přesměrování nastaveno. Kdokoli vám bude volat, bude
                  automaticky přepojen na vašeho asistenta.
                </li>
              </ol>
            </FaqItem>
          </div>
          <div class="mb-5">
            <Title :level="2" class="mb-3"
              >Jak nastavit přesměrování u operátora:</Title
            >
            <FaqItem question="Jak nastavit přesměrování hovorů O2">
              <p>
                Přesměrování si každý uživatel může sám na svém telefonu zapojit
                nebo vypojit tak, jak potřebuje. Obvykle najde zadání pro
                přesměrování pod Službami sítě nebo pod Nastavení telefonu (nebo
                Nastavení volání) - Přesměrování.
              </p>
              <div>
                <Title :level="4" class="mb-3 mt-5 color--theme-green"
                  >Nastavte si přesměrování pokud nezvedáte hovor - kód
                  61</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**61*[telefonní číslo]**[čas]#</strong
                  >
                  jako hovor (čas znamená kolik vteřin bude telefon vyzvánět,
                  vyplňte číslici)
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:%23%2361%23">##61#</a></strong>
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2361%23">*#61#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Přesměrování pokud JSTE MIMO SIGNÁL NEBO je váš telefon
                  vypnutý 62</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**62*[telefonní číslo]#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte <strong class="color--theme-orange"><a href="tel:%23%2362%23">##62#</a></strong> a
                  odešlete jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2362%23">*#62#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >MÁTE OBSAZENO - kód 67</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**67*[telefonní číslo]#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte <strong class="color--theme-orange"><a href="tel:%23%2367%23">##67#</a></strong> a
                  odešlete jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2367%23">*#67#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Pokud chcete přesměrovat všechny příchozí hovory - kód
                  21</Title
                >
                <p>Pozor - váš Telefon poté nebude vůbec zvonit</p>
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**21*[telefonní číslo]#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Napište bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:%23%2321%23">##21#</a></strong> a odešlete
                  jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Napište bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2321%23">*#21#</a></strong> a odešlete
                  jako hovor
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Zrušení všech přejmenování</Title
                >
                <strong class="color--theme-orange"></strong>
                <p>
                  Napište bez mezer kód
                  <strong class="color--theme-orange">##002#</strong> a odešlete
                  jako hovor
                </p>
              </div>
            </FaqItem>
            <FaqItem question="Jak nastavit přesměrování hovorů T-Mobile">
              <p>
                Přesměrování si každý uživatel může sám na svém telefonu zapojit
                nebo vypojit tak, jak potřebuje. Obvykle najde zadání pro
                přesměrování pod Službami sítě nebo pod Nastavení telefonu (nebo
                Nastavení volání) - Přesměrování.
              </p>
              <div>
                <Title :level="4" class="mb-3 mt-5 color--theme-green"
                  >Nastavte si přesměrování pokud nezvedáte hovor - kód
                  61</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**61*+420[telefonní číslo]*11#</strong
                  ><br />
                  <strong>telefonní číslo</strong> - zadejte celé telefonní
                  číslo, kam se mají hovory přesměrovávat, v národním formátu,
                  tedy například 212345678
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:%23%2361%23">##61#</a></strong>
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2361%23">*#61#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Přesměrování pokud JSTE MIMO SIGNÁL NEBO je váš telefon
                  vypnutý 62</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**62*+420[telefonní číslo]*11#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte <strong class="color--theme-orange"><a href="tel:%23%2362%23">##62#</a></strong> a
                  odešlete jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2362%23">*#62#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >MÁTE OBSAZENO - kód 67</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**67*+420[telefonní číslo]*11#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte <strong class="color--theme-orange"><a href="tel:%23%2367%23">##67#</a></strong> a
                  odešlete jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2367%23">*#67#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Příklad</Title
                >
                <p>
                  Přesměrování hlasových služeb na číslo 212345678, je-li váš
                  telefon vypnutý nebo mimo dosah signálu nastavíte pomocí kódu
                  **62*+420212345678*11#
                </p>
              </div>
            </FaqItem>
            <FaqItem question="Jak nastavit přesměrování hovorů Vodafone">
              <p>
                Přesměrování si každý uživatel může sám na svém telefonu zapojit
                nebo vypojit tak, jak potřebuje. Obvykle najde zadání pro
                přesměrování pod Službami sítě nebo pod Nastavení telefonu (nebo
                Nastavení volání) - Přesměrování.
              </p>
              <div>
                <Title :level="4" class="mb-3 mt-5 color--theme-green"
                  >Nastavte si přesměrování pokud nezvedáte hovor - kód
                  61</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**61*[telefonní číslo]**[čas]#</strong
                  >
                  jako hovor (čas znamená kolik vteřin bude telefon vyzvánět,
                  vyplňte číslici)
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:%23%2361%23">##61#</a></strong>
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2361%23">*#61#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Přesměrování pokud JSTE MIMO SIGNÁL NEBO je váš telefon
                  vypnutý 62</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**62*[telefonní číslo]#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte <strong class="color--theme-orange"><a href="tel:%23%2362%23">##62#</a></strong> a
                  odešlete jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2362%23">*#62#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >MÁTE OBSAZENO - kód 67</Title
                >
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**67*[telefonní číslo]#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Vytočte <strong class="color--theme-orange"><a href="tel:%23%2367%23">##67#</a></strong> a
                  odešlete jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2367%23">*#67#</a></strong>
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green"
                  >Pokud chcete přesměrovat všechny příchozí hovory - kód
                  21</Title
                >
                <p>Pozor - váš Telefon poté nebude vůbec zvonit</p>
                <strong class="color--theme-orange">Zapnutí: </strong>
                <p>
                  Vytočte bez mezer kód
                  <strong class="color--theme-orange"
                    >**21*[telefonní číslo]#</strong
                  >
                </p>
                <strong class="color--theme-orange">Vypnutí: </strong>
                <p>
                  Napište bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:%23%2321%23">##21#</a></strong> a odešlete
                  jako hovor
                </p>
                <strong class="color--theme-orange">Kontrola stavu:</strong>
                <p>
                  Napište bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:*%2321%23">*#21#</a></strong> a odešlete
                  jako hovor
                </p>
              </div>
              <div>
                <Title :level="4" class="mb-3  mt-5 color--theme-green">
                  Zrušení všech přejmenování
                </Title>
                <strong class="color--theme-orange"></strong>
                <p>
                  Napište bez mezer kód
                  <strong class="color--theme-orange"><a href="tel:%23%23002%23">##002#</a></strong> a odešlete
                  jako hovor
                </p>
              </div>
            </FaqItem>
          </div>
          <div class="mb-5">
            <Title :level="2" class="mb-3">Nejčastější otázky</Title>
            <FaqItem question="Jak si vypnout přesměrování schránky?">
              Vypnutí přesměrování je detailně popsané přímo v sekci nastavení
              vašeho operátora.
            </FaqItem>
            <FaqItem question="Jak si změnit číslo?">
              Změnit číslo vaší schránky bohužel nejde. Číslo je statické pro
              každou schránku.
            </FaqItem>
            <FaqItem question="Jak zrušit předplatné TooBizi">
              Předplatné můžete zrušit přímo ve svém profilu kliknutím na
              “Zrušit”.
            </FaqItem>
            <FaqItem question="Jak si změnit tarif?">
              Pro změnu tarifu nás prosím kontaktujte e-mailem na
              <a href="mailto:jsem@toobizi.cz"> jsem@toobizi.cz</a>
            </FaqItem>
            <FaqItem question="Existuje jiná možnost než SMS a e-mail?">
              Aktuálně nabízíme pouze tyto možnosti. Připravujeme napojení na
              Whatsapp, Telegram, Slack atd.
            </FaqItem>
            <FaqItem question="Mohu používat zahraniční operátory?">
              Naše schránka funguje pouze jako přesměrování, můžete proto
              používat jakého operátora chcete. Doporučujeme to však předtím
              konzultovat přímo s vaším operátorem.
            </FaqItem>
            <FaqItem question="Existuje asistent v cizím jazyce?">
              Aktuálně umí naše virtuální asistentka pouze česku. Přihlašujeme
              ji však na kurz slovenštiny a angličtiny. V dohledné době tak
              budeme mluvit i těmito jazyky :))
            </FaqItem>
          </div>
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import FaqItem from "@/components/FaqItem";
import Title from "@/components/Title";
import Section from "@/components/Section";

export default {
  components: {
    PageLayout,
    Section,
    FaqItem,
    Title
  }
};
</script>
