const stateHandlers = {
  getters: {
    scenarios: state => state.scenarios,
    getScenarioById: state => id =>
      state.scenarios.find(scenario => scenario.scenarioId === id),
    currentScenario: state =>
      state.scenarios.find(
        scenario => scenario.scenarioId === state.currentScenarioId
      ),
    availableTariffs: state =>
      state.availableTariffs.sort((a, b) => a.data.price.cs - b.data.price.cs),
    currentTariffId: state => state.currentTariffId,
    currentTariff: state => {
      const tarifs = state.availableTariffs.filter(
        tarif => tarif.id === state.currentTariffId
      );
      return tarifs.length === 1 ? tarifs[0] : { data: {} };
    },
    isScenarioActive: (_state, getters) => id =>
      getters.getScenarioById(id).active,
    hasOrderScenario: state => state.scenarios.length > 0,
    paymentUrl: state => state.paymentUrl,
    paymentsOverview: state => state.paymentsOverview,
    cardOverview: state => state.cardOverview,
    reccurentPaymentEnable: state => state.reccurentPaymentEnable
  },

  mutations: {
    setScenarios: (state, data) => (state.scenarios = data),

    setCurrentScenario: (state, scenarioId) =>
      (state.currentScenarioId = scenarioId),

    setCurrentTariffId: (state, tariffId) => (state.currentTariffId = tariffId),

    setAvailableTariffs: (state, tariffs) => (state.availableTariffs = tariffs),

    setPaymentUrl: (state, url) => (state.paymentUrl = url),

    setPaymentsOverview: (state, paymentsOverview) =>
      (state.paymentsOverview = paymentsOverview),

    setCardOverview: (state, cardOverview) =>
      (state.cardOverview = cardOverview),

    changeReccurentPayment: (state, payload) =>
      (state.reccurentPaymentEnable = payload)
  }
};

export default stateHandlers;
