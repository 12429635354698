import {
  GDPR_DIALOG,
  SCENARIO_DEACTIVATE_DIALOG,
  PAYMENT_AGREEMENT_DIALOG,
  CHANGE_PAYMENT_DIALOG
} from "@/constants/dialogs";
import stateHandlers from "./stateHandlers";

const dialogModule = {
  namespaced: true,
  state: {
    [GDPR_DIALOG]: false,
    [SCENARIO_DEACTIVATE_DIALOG]: false,
    [PAYMENT_AGREEMENT_DIALOG]: false,
    [CHANGE_PAYMENT_DIALOG]: false
  },
  ...stateHandlers
};

export default dialogModule;
