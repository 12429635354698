<template>
  <PageLayout withoutHeader withoutFooter>
    <Section>
      <router-link to="/">
        <img
          src="@/assets/images/Logo.svg"
          class="section__header-logo"
          alt="logo"
        />
      </router-link>
      <b-row>
        <b-col md="8" offset-md="2" lg="6" xl="5" offset-lg="5">
          <Title :level="1" className="mb-5">Přihlášení / Registrace</Title>
          <LoginForm />
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Section from "@/components/Section";
import Title from "@/components/Title";
import LoginForm from "@/components/LoginForm";

export default {
  name: "Login",
  components: {
    PageLayout,
    Section,
    Title,
    LoginForm
  }
};
</script>
