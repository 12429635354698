var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'button',
    _vm.theme ? ("button--" + _vm.theme) : '',
    _vm.isActive ? 'button--active' : '',
    _vm.disabled ? 'button--disabled' : '',
    _vm.noAction ? 'button--no-action' : '',
    _vm.notReady ? 'button--not-ready' : '',
    _vm.className ? _vm.className : ''
  ],on:{"click":_vm.actionHandler}},[(_vm.theme === 'icon-button' && _vm.icon)?_c('img',{staticClass:"mr-3",attrs:{"src":require(("@/assets/images/" + _vm.icon + ".svg")),"alt":"icon"}}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }