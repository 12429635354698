<template>
  <DialogWindow
    :name="dialogs.PAYMENT_AGREEMENT_DIALOG"
    title="Pravidelná měsíční platba"
  >
    <TextBlock type="p">
      Platba se vždy provádí za následující měsíc. Platbu lze kdykoliv zrušit, v
      tom případě zůstane služba akitvní do konce již zaplaceného období. Před
      každou automatickou platbou obdržíte s předstihem několika dní informační
      email s oznámením platby.
    </TextBlock>
    <Button
      theme="primary"
      isActive
      :actionHandler="() => toggleDialog(dialogs.PAYMENT_AGREEMENT_DIALOG)"
      className="d-block mx-auto mt-5"
    >
      Rozumím
    </Button>
  </DialogWindow>
</template>

<script>
import { mapMutations } from "vuex";
import DialogWindow from "@/components/DialogWindow";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import { PAYMENT_AGREEMENT_DIALOG } from "@/constants/dialogs";
import { DIALOG_MODULE } from "@/constants/modules";

export default {
  name: "PaymentAgreementDialog",
  components: {
    DialogWindow,
    TextBlock,
    Button
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"])
  },
  data: () => ({
    dialogs: { PAYMENT_AGREEMENT_DIALOG }
  })
};
</script>
