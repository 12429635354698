<template>
  <a :href="to" class="link" :target="openInNewWindow ? '_blank' : '_self'">
    <slot />
  </a>
</template>

<script>
import "./ExternalLink.scss";

export default {
  name: "ExternalLink",
  props: {
    to: String,
    openInNewWindow: {
      type: Boolean,
      default: true
    }
  }
};
</script>
