import Vue from "vue";
import Vuex from "vuex";

import apiModule from "./globalModules/apiModule";
import formModule from "./globalModules/formModule";
import dialogModule from "./globalModules/dialogModule";
import userModule from "./singleModules/userModule";
import orderModule from "./singleModules/orderModule";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    apiModule,
    formModule,
    dialogModule,
    userModule,
    orderModule
  }
});

export default store;
