import stateHandlers from "./stateHandlers";
import actions from "./actions";

const orderModule = {
  namespaced: true,
  state: {
    scenarios: [],
    currentScenarioId: "",
    availableTariffs: [],
    currentTariffId: "",
    paymentUrl: "",
    paymentsOverview: [],
    cardOverview: {},
    reccurentPaymentEnable: false
  },
  ...stateHandlers,
  actions
};

export default orderModule;
