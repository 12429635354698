var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pricing-group animated",class:[_vm.highlighted ? 'pricing-group--highlighted' : '', _vm.className]},[(_vm.sale)?_c('TextBlock',{staticClass:"pricing-group__sale",class:_vm.saleColor && 'pricing-group__sale--' + _vm.saleColor,attrs:{"type":"p"}},[_vm._v(_vm._s(_vm.sale)+" ")]):_vm._e(),_c('b-row',[_c('b-col',[_c('Title',{attrs:{"level":2,"className":"pricing-group__title mb-3"}},[_vm._v(_vm._s(_vm.title))]),_c('TextBlock',{attrs:{"type":"p","className":"pricing-group__subtitle mb-5"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_c('ul',{staticClass:"pricing-group__benefits  mb-5"},_vm._l((_vm.benefits),function(benefit){return _c('li',{key:benefit.index},[_vm._v(" "+_vm._s(benefit.name)+" ")])}),0),_c('TextBlock',{staticClass:"pricing-group__price",class:[
          _vm.price
            ? 'pricing-group__price--old'
            : 'pricing-group__price--original'
        ],attrs:{"type":"p"}},[_vm._v(_vm._s(_vm.salePrice)+" ")]),(_vm.price)?_c('TextBlock',{staticClass:"pricing-group__price pricing-group__price--original",attrs:{"type":"p"}},[_vm._v(_vm._s(_vm.price)+" ")]):_vm._e(),(!_vm.order)?_c('Button',{attrs:{"theme":"pricing","className":"mt-2","actionHandler":function () {
            this$1.gtmTrack();
            this$1.$router.push('/login');
          }}},[_vm._v("Vyzkoušet ")]):_vm._e(),(_vm.order)?_c('Button',{attrs:{"theme":"pricing","className":"mt-2","actionHandler":_vm.selectAction}},[_vm._v("Vybrat ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }