// Error messages
const NO_EMPTY_MSG = "Pole nesmí být prázdné";
const EMAIL_MSG = "Neplatný formát emailu";
const CHECKED_MSG = "Pole je povinné";
const ZIP_MSG = "PSČ musí obsahovat 5 číslic";
const PHONE_MSG = "Neplatný formát telefonního čísla";
const REGISTRATION_NO_MSG = "Neplatný formát identifikačního čísla";

// Patterns
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ZIP_PATTERN = /^\d{5}$/;
const PHONE_PATTERN = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
const REGISTRATION_NO_PATTERN = /^[0-9]{8}$/;

// Validations
export const noEmpty = val => (val.length > 0 ? "" : NO_EMPTY_MSG);

export const checked = val => (val ? "" : CHECKED_MSG);

export const email = val =>
  EMAIL_PATTERN.test(val.toLowerCase()) ? "" : EMAIL_MSG;

export const zip = val =>
  ZIP_PATTERN.test(val.replace(/\s/g, "")) ? "" : ZIP_MSG;

export const phoneNumber = val => (PHONE_PATTERN.test(val) ? "" : PHONE_MSG);

export const registrationNo = val =>
  val.length === 0
    ? ""
    : REGISTRATION_NO_PATTERN.test(val)
    ? ""
    : REGISTRATION_NO_MSG;
