<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col
          v-if="isActionFetching(actions.CHECK_VERIFY_EMAIL)"
          md="8"
          offset-md="2"
        >
          <Title :level="1" className="mb-5">Probíhá ověřování emailu...</Title>
          <Loader />
        </b-col>
        <b-col
          v-if="isActionSuccess(actions.CHECK_VERIFY_EMAIL)"
          md="8"
          offset-md="2"
        >
          <Title :level="1" className="mb-5">Email byl úspěšně ověřen</Title>
          <TextBlock type="p" className="pt-5 pb-5"
            >Vítáme Vás vážený zákazníku! <br />
            Nyní můžete pokračovat v objednávce
          </TextBlock>
          <Button
            theme="primary"
            isActive
            :actionHandler="() => $router.push('/order-overview')"
          >
            Pokračovat
          </Button>
        </b-col>
        <b-col
          v-if="isActionError(actions.CHECK_VERIFY_EMAIL)"
          md="8"
          offset-md="2"
        >
          <Title :level="1" className="mb-5">Email se nepodařilo ověřit</Title>
          <TextBlock type="p" className="pt-5 pb-5">
            Při ověřování došlo k chybě
          </TextBlock>
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import { CHECK_VERIFY_EMAIL } from "@/constants/actions";
import { API_MODULE, USER_MODULE, ORDER_MODULE } from "@/constants/modules";

export default {
  name: "VerifyCheck",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    Button,
    Loader
  },
  data: () => ({
    actions: { CHECK_VERIFY_EMAIL }
  }),
  methods: {
    ...mapActions(USER_MODULE, [CHECK_VERIFY_EMAIL])
  },
  computed: {
    ...mapGetters(API_MODULE, [
      "isActionFetching",
      "isActionSuccess",
      "isActionError"
    ]),
    ...mapGetters(ORDER_MODULE, [
      "hasOrderScenario",
      "isScenarioActive",
      "currentScenario"
    ])
  },
  mounted() {
    const verifyCode = this.$route.query.code ? this.$route.query.code : "";
    this[CHECK_VERIFY_EMAIL](verifyCode);
  }
};
</script>
