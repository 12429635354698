<template>
  <div
    :class="[
      'form-group',
      className ? className : '',
      type === 'checkbox' ? 'form-group--flex' : '',
      error.length > 0 && isTouched ? 'form-group--error' : ''
    ]"
  >
    <template v-if="type === 'checkbox'">
      <input
        type="checkbox"
        :name="name"
        :checked="value"
        class="form-group__checkbox"
        @input="e => inputHandler(e.target.checked)"
        @focusout="isTouched = true"
      />
      <label
        v-if="label"
        :for="name"
        class="form-group__label form-group__label--checkbox"
      >
        {{ label }}
        <Button
          v-if="labelLink"
          theme="link"
          :actionHandler="
            () =>
              labelLink.action
                ? labelLink.action()
                : openExternalUrl(labelLink.url)
          "
        >
          {{ labelLink.label }}
        </Button>
      </label>
    </template>
    <template v-else>
      <label
        v-if="label"
        :for="name"
        :class="['form-group__label', sublabel ? '' : 'mb-3']"
      >
        {{ label }}
      </label>
      <TextBlock
        v-if="sublabel"
        type="span"
        class="d-block text-block--sublabel mb-2"
      >
        {{ sublabel }}
      </TextBlock>
      <textarea
        v-if="rows"
        class="form-group__input"
        :name="name"
        :value="value"
        @input="e => inputHandler(e.target.value)"
        @focusout="isTouched = true"
        :rows="rows"
        :placeholder="placeholder ? placeholder : null"
      >
      </textarea>
      <select
        v-else-if="options"
        class="form-group__select"
        :name="name"
        :value="value"
        @input="e => inputHandler(e.target.value)"
        :placeholder="placeholder ? placeholder : null"
      >
        <option v-for="(option, index) in options" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
      <input
        v-else
        class="form-group__input"
        :type="type"
        :name="name"
        :value="value"
        @input="e => inputHandler(e.target.value)"
        @focusout="isTouched = true"
        :placeholder="placeholder ? placeholder : null"
      />
    </template>
    <TextBlock
      v-if="error.length > 0 && (isTouched || isFormSubmitTouched(form))"
      type="span"
      className="d-block form-group__error-message"
    >
      {{ error }}
    </TextBlock>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import openExternalUrl from "@/helpers/openExternalUrl";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import { FORM_MODULE } from "@/constants/modules";
import "./FormGroup.scss";

export default {
  data: () => ({
    error: "",
    isTouched: false,
    openExternalUrl
  }),
  name: "FormGroup",
  components: {
    TextBlock,
    Button
  },
  props: {
    label: String,
    labelLink: Object,
    name: String,
    value: [String, Boolean],
    rows: Number,
    options: Array,
    sublabel: String,
    placeholder: String,
    className: String,
    validate: Array,
    form: String,
    type: {
      type: String,
      default: "text"
    }
  },
  mounted() {
    setTimeout(() => this.inputHandler(this.value), 100);
  },
  methods: {
    ...mapMutations(FORM_MODULE, ["addFormError", "removeFormError"]),

    inputHandler(value) {
      this.$emit("input", value);

      if (this.validate) {
        const failValidation = this.validate.find(
          validation => validation(value) !== ""
        );

        if (failValidation) {
          this.error = failValidation(value);
          this.addFormError({
            form: this.form,
            errorField: this.name
          });
        } else {
          this.error = "";
          this.removeFormError({
            form: this.form,
            errorField: this.name
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters(FORM_MODULE, ["isFormSubmitTouched"])
  }
};
</script>
