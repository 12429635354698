<template>
  <div class="fade-sentences">
    <div v-for="index in count" class="fade-sentences__item" :key="index">
      {{ sentence }}
    </div>
  </div>
</template>

<script>
import "./FadeSentences.scss";

export default {
  data() {
    return {};
  },
  name: "FadeSentences",
  props: {
    sentence: String,
    count: Number
  },
  methods: {}
};
</script>
