import { ACTION_SUCCESS, ACTION_ERROR, ACTION_FETCHING } from "@/constants";

const stateHandlers = {
  getters: {
    isActionFetching: state => action => state[action] === ACTION_FETCHING,
    isActionSuccess: state => action => state[action] === ACTION_SUCCESS,
    isActionError: state => action => state[action] === ACTION_ERROR,
    isActionNoState: state => action => state[action] === ""
  },
  mutations: {
    setActionState: (state, { action, actionState }) =>
      (state[action] = actionState),

    resetActionState: (state, action) => (state[action] = "")
  }
};

export default stateHandlers;
