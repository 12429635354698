<template>
  <FormContainer>
    <FormGroup
      :form="forms.PERSONAL_DATA_FORM"
      placeholder="Jméno*"
      name="name"
      v-model="name"
      :validate="[validations.noEmpty]"
      className="mb-4"
    />
    <FormGroup
      :form="forms.PERSONAL_DATA_FORM"
      placeholder="Přijmení*"
      name="surname"
      v-model="surname"
      :validate="[validations.noEmpty]"
      className="mt-2 mb-4"
    />
    <FormGroup
      :form="forms.PERSONAL_DATA_FORM"
      placeholder="Ulice*"
      name="street"
      v-model="street"
      :validate="[validations.noEmpty]"
      className="mt-2 mb-4"
    />
    <FormGroup
      :form="forms.PERSONAL_DATA_FORM"
      placeholder="Město*"
      name="city"
      v-model="city"
      :validate="[validations.noEmpty]"
      className="mt-2 mb-4"
    />
    <FormGroup
      :form="forms.PERSONAL_DATA_FORM"
      placeholder="PSČ*"
      name="zip"
      v-model="zip"
      :validate="[validations.noEmpty, validations.zip]"
      className="mt-2 mb-4"
    />
    <FormGroup
      :form="forms.PERSONAL_DATA_FORM"
      placeholder="IČO"
      name="registrationNo"
      v-model="registrationNo"
      :validate="[validations.registrationNo]"
      className="mt-2 mb-4"
    />
  </FormContainer>
</template>

<script>
import FormContainer from "@/components/FormContainer";
import FormGroup from "@/components/FormGroup";
import mapFields from "@/helpers/mapFields";
import { PERSONAL_DATA_FORM } from "@/constants/forms";
import { FORM_MODULE, USER_MODULE } from "@/constants/modules";
import { SUBMIT_FORM, EDIT_USER_PERSONAL_DATA } from "@/constants/actions";
import { noEmpty, zip, registrationNo } from "@/helpers/validations";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    forms: { PERSONAL_DATA_FORM },
    validations: { noEmpty, zip, registrationNo }
  }),
  name: "PersonalDataForm",
  components: {
    FormContainer,
    FormGroup
  },
  methods: {
    ...mapActions(FORM_MODULE, [SUBMIT_FORM]),
    ...mapActions(USER_MODULE, [EDIT_USER_PERSONAL_DATA]),
    ...mapMutations(FORM_MODULE, ["setFormValues", "setFormUntouched"]),

    submitPersonalDataForm() {
      this[SUBMIT_FORM]({
        form: PERSONAL_DATA_FORM,
        action: values =>
          this[EDIT_USER_PERSONAL_DATA]({
            ...values,
            name: `${values.name} ${values.surname}`
          })
      });
    }
  },
  computed: {
    ...mapGetters(USER_MODULE, ["userPersonalData"]),
    ...mapGetters(FORM_MODULE, ["getFormValues"]),
    ...mapFields({
      form: PERSONAL_DATA_FORM,
      fields: ["name", "surname", "street", "city", "zip", "registrationNo"]
    })
  },
  mounted() {
    if (this.userPersonalData) {
      const formValues = this.getFormValues(PERSONAL_DATA_FORM);
      const [name, ...surname] = this.userPersonalData.name
        ? this.userPersonalData.name.split(" ")
        : ["", ""];

      this.setFormValues({
        form: PERSONAL_DATA_FORM,
        values: {
          ...formValues,
          ...this.userPersonalData,
          name,
          surname: surname.join(" ")
        }
      });
    }
  }
};
</script>
