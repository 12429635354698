<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex";
import { CHECK_COOKIE_AGREEMENT } from "@/constants/actions";
import { USER_MODULE } from "@/constants/modules";

import "@/assets/scss/front.scss";

export default {
  name: "App",
  methods: {
    ...mapActions(USER_MODULE, [CHECK_COOKIE_AGREEMENT])
  },
  mounted() {
    this[CHECK_COOKIE_AGREEMENT]();
  }
};
</script>
