<template>
  <div>
    <div class="gdpr-button" @click="toggleDialog(dialogs.GDPR_DIALOG)">
      <img src="@/assets/images/gdpr-compliant.svg" alt="gdpr compliant" />
    </div>
    <GdprDialog />
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import GdprDialog from "@/components/GdprDialog";
import { GDPR_DIALOG } from "@/constants/dialogs";
import { DIALOG_MODULE } from "@/constants/modules";

import "./GdprButton.scss";

export default {
  name: "GdprButton",
  data: () => ({
    dialogs: { GDPR_DIALOG }
  }),
  components: {
    GdprDialog
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"])
  }
};
</script>
