const stateHandlers = {
  getters: {
    isUserAuthenticated: state => state.isUserAuthenticated,
    userEmail: state => state.userEmail,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    isCookieAgreed: state => state.isCookieAgreed,
    userPersonalData: state => state.userPersonalData
  },
  mutations: {
    setUserAuthenticated: (state, authState) =>
      (state.isUserAuthenticated = authState),

    setUserEmail: (state, email) => (state.userEmail = email),

    setAccessToken: (state, token) => (state.accessToken = token),

    setRefreshToken: (state, token) => (state.refreshToken = token),

    setCookieAgreed: (state, isCookieAgreed) =>
      (state.isCookieAgreed = isCookieAgreed),

    setUserPersonalData: (state, userData) => {
      const userDataWithoutEmptyProps = Object.keys(userData)
        .filter(key => userData[key] !== null && userData[key] !== undefined)
        .reduce((obj, key) => {
          obj[key] = userData[key];
          return obj;
        }, {});

      state.userPersonalData = userDataWithoutEmptyProps;
    }
  }
};

export default stateHandlers;
