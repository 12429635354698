<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col class="text-center">
          <Title :level="1" className="mb-5"
            >Vaše datová schránka byla deaktivována</Title
          >
          <Button
            theme="primary"
            className="mt-5"
            :actionHandler="() => this.$router.push('/')"
          >
            Zpět na homepage
          </Button>
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import Button from "@/components/Button";

export default {
  name: "ScenarioRemoved",
  components: {
    PageLayout,
    Title,
    Section,
    Button
  }
};
</script>
