<template>
  <DialogWindow
    :name="dialogs.CHANGE_PAYMENT_DIALOG"
    :title="isPaymentActive ? 'Zrušení platby' : 'Obnovení platby'"
  >
    <div
      v-if="
        isActionSuccess(actions.CHANGE_PAYMENT) ||
          isActionError(actions.CHANGE_PAYMENT)
      "
    >
      <TextBlock v-if="isActionSuccess(actions.CHANGE_PAYMENT)" type="p">
        Zpracování vašeho požadavku proběhlo úspěšně.
      </TextBlock>
      <TextBlock v-else type="p" color="red">
        Při zpracování vašeho požadavku došlo k chybě. Zkuste to prosím znovu.
      </TextBlock>
      <div class="text-center">
        <Button
          theme="primary"
          isActive
          :actionHandler="() => toggleDialog(dialogs.CHANGE_PAYMENT_DIALOG)"
          className="mt-5 mr-5"
        >
          Zavřít
        </Button>
      </div>
    </div>
    <div v-if="isActionFetching(actions.CHANGE_PAYMENT)">
      <Loader />
    </div>
    <div v-if="isActionNoState(actions.CHANGE_PAYMENT)">
      <TextBlock v-if="isPaymentActive" type="p">
        Opravdu chcete platbu zrušit? Datová schránka zůstane aktivní do konce
        již zaplaceného období a poté bude deaktivována. Pro její opětovnou
        aktivaci bude potřeba platbu znovu obnovit.
      </TextBlock>
      <TextBlock v-else type="p">
        Po obnovení platby se na konci již zaplaceného období automaticky
        provede platba z vaší karty a platnost hlasové asistentky se prodlouží.
      </TextBlock>
      <div class="text-center">
        <Button
          theme="link"
          isActive
          :actionHandler="() => toggleDialog(dialogs.CHANGE_PAYMENT_DIALOG)"
          className="mt-5 mr-5"
        >
          Zpět
        </Button>
        <Button
          theme="primary"
          isActive
          :actionHandler="
            () => this[actions.CHANGE_PAYMENT]({ enable: !isPaymentActive })
          "
          className="mt-5"
        >
          Potvrdit
        </Button>
      </div>
    </div>
  </DialogWindow>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DialogWindow from "@/components/DialogWindow";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import { CHANGE_PAYMENT_DIALOG } from "@/constants/dialogs";
import { DIALOG_MODULE, ORDER_MODULE, API_MODULE } from "@/constants/modules";
import { CHANGE_PAYMENT } from "@/constants/actions";

export default {
  name: "ChangePaymentDialog",
  components: {
    DialogWindow,
    TextBlock,
    Button,
    Loader
  },
  data: () => ({
    dialogs: { CHANGE_PAYMENT_DIALOG },
    actions: { CHANGE_PAYMENT }
  }),
  props: {
    isPaymentActive: Boolean
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"]),
    ...mapActions(ORDER_MODULE, [CHANGE_PAYMENT])
  },
  computed: {
    ...mapGetters(API_MODULE, [
      "isActionFetching",
      "isActionError",
      "isActionSuccess",
      "isActionNoState"
    ])
  }
};
</script>
