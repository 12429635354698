<template>
  <div v-if="isDialogOpen(name)">
    <div class="dialog-window__overlay" />
    <div class="dialog-window">
      <img
        src="@/assets/images/cross.svg"
        class="dialog-window__close"
        alt="close"
        @click="toggleDialog(name)"
      />
      <TextBlock type="span" class="dialog-window__title">
        {{ title }}
      </TextBlock>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TextBlock from "@/components/TextBlock";
import { DIALOG_MODULE } from "@/constants/modules";

import "./DialogWindow.scss";

export default {
  name: "DialogWindow",
  components: { TextBlock },
  props: {
    name: String,
    title: String
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"])
  },
  computed: {
    ...mapGetters(DIALOG_MODULE, ["isDialogOpen"])
  }
};
</script>
