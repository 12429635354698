<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col v-if="scenarioData" md="6">
          <Title :level="1">Úprava scénáře</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
          <ScenarioForm ref="scenarioForm" :scenarioData="scenarioData" />
        </b-col>
        <b-col v-else md="8" offset-md="2">
          <Title :level="1">Neplatný scénář</Title>
          <TextBlock type="span" className="d-block">
            Scénář neexistuje nebo k jeho zobrazení nemáte dostatečná oprávnění
          </TextBlock>
        </b-col>
      </b-row>
    </Section>
    <ActionStrip v-if="scenarioData" :actionHandler="sendScenarioForm">
      Uložit úpravy
    </ActionStrip>
  </PageLayout>
</template>

<script>
import { mapGetters } from "vuex";

import { ORDER_MODULE } from "@/constants/modules";
import PageLayout from "@/components/PageLayout";
import ScenarioForm from "@/components/ScenarioForm";
import Title from "@/components/Title";
import Section from "@/components/Section";
import ActionStrip from "@/components/ActionStrip";
import TextBlock from "@/components/TextBlock";

export default {
  name: "EditScenario",
  components: {
    PageLayout,
    ScenarioForm,
    Title,
    Section,
    ActionStrip,
    TextBlock
  },
  data: () => ({
    scenarioId: "",
    scenarioData: null
  }),
  methods: {
    sendScenarioForm() {
      this.$refs.scenarioForm.sendScenarioForm();
    }
  },
  computed: {
    ...mapGetters(ORDER_MODULE, ["getScenarioById"])
  },
  mounted() {
    this.scenarioId = this.$route.params.id;
    const scenario = this.getScenarioById(this.scenarioId);
    scenario
      ? (this.scenarioData = {
          phoneNumber: scenario.phoneNumber,
          ...JSON.parse(scenario.data)
        })
      : null;
  }
};
</script>
