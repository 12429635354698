import setFormState from "@/helpers/setFormState";
import {
  LOGIN_FORM,
  SCENARIO_FORM,
  PAYMENT_FORM,
  LOGIN_FROM_DEFAULT,
  SCENARIO_FORM_DEFAULT,
  PAYMENT_FORM_DEFAULT,
  CONTACT_FORM,
  CONTACT_FORM_DEFAULT,
  PERSONAL_DATA_FORM,
  PERSONAL_DATA_FORM_DEFAULT,
  TARIFF_FORM,
  TARIFF_FORM_DEFAULT
} from "@/constants/forms";
import stateHandlers from "./stateHandlers";
import actions from "./actions";

const formModule = {
  namespaced: true,
  state: {
    ...setFormState(LOGIN_FORM, LOGIN_FROM_DEFAULT),
    ...setFormState(SCENARIO_FORM, SCENARIO_FORM_DEFAULT),
    ...setFormState(PAYMENT_FORM, PAYMENT_FORM_DEFAULT),
    ...setFormState(CONTACT_FORM, CONTACT_FORM_DEFAULT),
    ...setFormState(PERSONAL_DATA_FORM, PERSONAL_DATA_FORM_DEFAULT),
    ...setFormState(TARIFF_FORM, TARIFF_FORM_DEFAULT)
  },
  ...stateHandlers,
  actions
};

export default formModule;
