<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="10" offset-md="2" lg="8">
          <Title :level="1">Objednávka</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
          <TextBlock type="span" className="d-block"
            >Zprávy budete dostávat na email</TextBlock
          >
          <TextBlock type="span" color="green" className="d-block mb-4">
            {{ userEmail }}
          </TextBlock>
          <TextBlock type="span" className="d-block mb-4 mb-md-3">
            Cena {{ currentScenario ? currentScenario.price : "" }} Kč
            {{ this.currentTariff.data.duration === 1 ? "ročně" : "měsíčně" }}
          </TextBlock>
          <Button
            theme="link"
            className="mb-5 mb-md-4"
            :actionHandler="
              () =>
                this.$router.push(
                  `/edit-scenario/${currentScenario.scenarioId}`
                )
            "
            >Upravit scénář</Button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" offset-md="2" lg="6" class="mt-5 mb-5">
          <NotificationBox type="info">
            Kliknutím na tlačítko Zaplatit souhlasíte s pravidelnou platbou,
            kterou vám budeme účtovat jednou
            {{ this.currentTariff.data.duration === 1 ? "ročně" : "měsíčně" }}
            ke dni Vaší registrace. Službu TooBizi můžete kdykoliv zrušit.
          </NotificationBox>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" lg="4" offset-md="2" class="mt-5 mt-md-0">
          <FormGroup
            :form="forms.PAYMENT_FORM"
            name="promocode"
            placeholder="Promocode"
            className="mb-0"
            v-model="promocode"
          />
          <Loader v-if="isActionFetching(actions.VERIFY_PROMO_CODE)" />
          <TextBlock
            v-else-if="isActionSuccess(actions.VERIFY_PROMO_CODE)"
            type="span"
            className="mt-2"
            successMessage
          >
            Promocode úspěšně ověřen
          </TextBlock>
          <Button
            v-else
            theme="link"
            :actionHandler="
              () => verifyPromoCode({ code: promocode, currentScenario })
            "
          >
            Použít
          </Button>
          <TextBlock
            v-if="isActionError(actions.VERIFY_PROMO_CODE)"
            type="span"
            className="mt-2"
            errorMessage
          >
            Promocode se nepodařilo ověřit
          </TextBlock>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" offset-md="2" class="mt-5">
          <FormGroup
            :form="forms.PAYMENT_FORM"
            name="conditionsAgreement"
            label="Souhlasím se"
            :labelLink="{
              label: 'všeobecnými obchodními podmínkami',
              url: require('@/assets/pdf/tb_obchodni_podminky.pdf')
            }"
            type="checkbox"
            className="mb-0"
            v-model="conditionsAgreement"
            :validate="[validations.checked]"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" offset-md="2">
          <FormGroup
            :form="forms.PAYMENT_FORM"
            name="paymentAgreement"
            label="Souhlasím s"
            :labelLink="{
              label: 'pravidelnou měsíční platbou',
              action: () => toggleDialog(dialogs.PAYMENT_AGREEMENT_DIALOG)
            }"
            type="checkbox"
            className="mb-0"
            v-model="paymentAgreement"
            :validate="[validations.checked]"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" offset-md="2">
          <FormGroup
            :form="forms.PAYMENT_FORM"
            name="gdprAgreement"
            label="Souhlasím se"
            :labelLink="{
              label: 'zpracováním osobních údajů',
              url: require('@/assets/pdf/tb_osobni_udaje.pdf')
            }"
            type="checkbox"
            v-model="gdprAgreement"
            :validate="[validations.checked]"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col offset-md="2">
          <img
            src="@/assets/images/the-pay-rounded.svg"
            alt="gopay"
            class="mr-3 mb-3 mb-sm-0"
          />
          <img
            src="@/assets/images/mastercard.svg"
            alt="mastercard"
            class="mr-3 mb-3 mb-sm-0"
          />
          <img
            src="@/assets/images/visa.svg"
            alt="visa"
            class="mr-3 mb-3 mb-sm-0"
          />
        </b-col>
      </b-row>
      <GdprButton />
    </Section>
    <ActionStrip
      :actionHandler="() => redirectToPayment()"
      :disabled="!isFormValid(forms.PAYMENT_FORM)"
    >
      {{ getActivateMessage() }}
    </ActionStrip>
    <PaymentAgreementDialog />
  </PageLayout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import { checked } from "@/helpers/validations";
import mapFields from "@/helpers/mapFields";
import { VERIFY_PROMO_CODE, GET_PAYMENT_DATA } from "@/constants/actions";
import { PAYMENT_FORM } from "@/constants/forms";
import { PAYMENT_AGREEMENT_DIALOG } from "@/constants/dialogs";
import {
  USER_MODULE,
  ORDER_MODULE,
  DIALOG_MODULE,
  API_MODULE,
  FORM_MODULE
} from "@/constants/modules";

import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import FormGroup from "@/components/FormGroup";
import ActionStrip from "@/components/ActionStrip";
import NotificationBox from "@/components/NotificationBox";
import Loader from "@/components/Loader";
import GdprButton from "@/components/GdprButton";
import PaymentAgreementDialog from "@/components/PaymentAgreementDialog";

export default {
  name: "OrderPayment",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    Button,
    FormGroup,
    ActionStrip,
    Loader,
    NotificationBox,
    GdprButton,
    PaymentAgreementDialog
  },
  data: () => ({
    actions: { VERIFY_PROMO_CODE, GET_PAYMENT_DATA },
    validations: { checked },
    forms: { PAYMENT_FORM },
    dialogs: { PAYMENT_AGREEMENT_DIALOG }
  }),
  methods: {
    ...mapActions(ORDER_MODULE, [GET_PAYMENT_DATA, VERIFY_PROMO_CODE]),
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"]),

    getActivateMessage() {
      if (this.currentScenario) {
        const msgText =
          this.currentScenario.priceToPay == 0
            ? "Aktivovat zdarma"
            : `Zaplatit ${this.currentScenario.priceToPay} ${
                this.currentTariff.data.duration === 1 ? "ročně" : "měsíčně"
              } / Aktivovat`;

        return msgText;
      }
    },

    redirectToPayment() {
      this.isActionSuccess(this.actions.VERIFY_PROMO_CODE)
        ? this.$router.push("/order-overview")
        : (window.location.href = this.paymentUrl);
    }
  },
  computed: {
    ...mapGetters(USER_MODULE, ["userEmail"]),
    ...mapGetters(ORDER_MODULE, [
      "currentScenario",
      "paymentUrl",
      "currentTariff"
    ]),
    ...mapGetters(API_MODULE, [
      "isActionSuccess",
      "isActionFetching",
      "isActionError"
    ]),
    ...mapGetters(FORM_MODULE, ["isFormValid"]),
    ...mapFields({
      form: PAYMENT_FORM,
      fields: [
        "conditionsAgreement",
        "paymentAgreement",
        "gdprAgreement",
        "promocode"
      ]
    })
  },

  mounted() {
    this[GET_PAYMENT_DATA]();
  }
};
</script>
