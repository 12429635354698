const stateHandlers = {
  getters: {
    isDialogOpen: state => dialog => state[dialog]
  },
  mutations: {
    toggleDialog: (state, dialog) => (state[dialog] = !state[dialog])
  }
};

export default stateHandlers;
