var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageLayout',{attrs:{"withoutFooter":""}},[_c('Section',[_c('b-row',[_c('b-col',{attrs:{"md":"7","offset-md":"2"}},[_c('Title',{attrs:{"level":1}},[_vm._v("Členství")]),_c('TextBlock',{staticClass:"text-block--heading-perex",attrs:{"type":"span"}},[_vm._v(" chytré hlasové asistentky ")])],1)],1),_c('OrderAdministration'),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"7","offset-md":"2"}},[_c('Title',{attrs:{"level":5,"className":"mb-3 mt-5"}},[_vm._v(" Nastavení přesměrování u operátora: ")]),_c('List',[_c('ListItem',[_vm._v("Pokud mám vypnutý telefon")]),_c('ListItem',[_vm._v("Nezvednu telefon do určitého času")]),_c('ListItem',[_vm._v("Zamítnu hovor")])],1),_c('Button',{attrs:{"theme":"secondary","className":"mr-4","actionHandler":function () { return _vm.openExternalUrl(
                '/faq#jak-nastavit-presmerovani-hovoru-vodafone'
              ); }}},[_vm._v(" Vodafone ")]),_c('Button',{attrs:{"theme":"secondary","className":"mr-4","actionHandler":function () { return _vm.openExternalUrl(
                '/faq#jak-nastavit-presmerovani-hovoru-t-mobile'
              ); }}},[_vm._v(" T-mobile ")]),_c('Button',{attrs:{"theme":"secondary","actionHandler":function () { return _vm.openExternalUrl('/faq#jak-nastavit-presmerovani-hovoru-o2'); }}},[_vm._v(" O2 ")])],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"7","offset-md":"2"}},[_c('Title',{attrs:{"level":5,"className":"mb-3 mt-5"}},[_vm._v(" Nastavení přesměrování v telefonu: ")]),_c('List',[_c('ListItem',[_vm._v("Přesměrování veškerých hovorů")])],1),_c('Button',{attrs:{"theme":"secondary","className":"mr-4","actionHandler":function () { return _vm.openExternalUrl(
                '/faq#jak-nastavit-presmerovani-hovoru-na-iphone-ios'
              ); }}},[_vm._v(" Pro Iphone ")]),_c('Button',{attrs:{"theme":"secondary","actionHandler":function () { return _vm.openExternalUrl(
                '/faq#jak-nastavit-presmerovani-hovoru-na-android'
              ); }}},[_vm._v(" Pro Android ")])],1)],1),_c('GdprButton'),_c('ScenarioDeactivateDialog')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }