<template>
  <span class="tag">{{ value }}</span>
</template>

<script>
import "./Tag.scss";

export default {
  name: "Tag",
  props: {
    value: String
  }
};
</script>
