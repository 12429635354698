import Vue from "vue";
import VueRouter from "vue-router";

import routesSettings from "./routesSettings";
import {
  accessNavigationGuard,
  testModeNavigationGuard
} from "./navigationGuards";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routesSettings,
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(({ meta: { testModeOnly, ...routeSettings } }, _from, next) =>
  testModeOnly
    ? testModeNavigationGuard(next)
    : accessNavigationGuard(next, routeSettings)
);

export default router;
