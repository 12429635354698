<template>
  <div class="countdown" v-if="distance > 0">
    <TextBlock type="p" className="pricing__sale">
      {{ title }}
    </TextBlock>
    <div class="countdown__inner">
      <div class="countdown__item">
        <span
          class="countdown__item__number"
          id="countdown__item__number--days"
        >
          {{ days }}</span
        ><span class="countdown__item__desc">Dní</span>
      </div>
      <div class="countdown__item">
        <span
          class="countdown__item__number"
          id="countdown__item__number--hours"
          >{{ hours }}</span
        ><span class="countdown__item__desc">hodin</span>
      </div>
      <div class="countdown__item">
        <span
          class="countdown__item__number"
          id="countdown__item__number--minutes"
        >
          {{ minutes }}</span
        ><span class="countdown__item__desc">minut</span>
      </div>
      <div class="countdown__item">
        <span
          class="countdown__item__number"
          id="countdown__item__number--seconds"
        >
          {{ seconds }}</span
        ><span class="countdown__item__desc">sekund</span>
      </div>
    </div>
  </div>
</template>

<script>
import "./Countdown.scss";
import TextBlock from "../TextBlock/TextBlock";

export default {
  name: "Countdown",
  components: TextBlock,
  props: {
    finalDate: String,
    title: String
  },
  data: () => ({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    counterInterval: null,
    distance: 0
  }),
  methods: {
    getDataFromDate() {
      var now = new Date().getTime();
      var countDownDate = new Date(this.finalDate).getTime();
      this.distance = countDownDate - now;
      this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor(
        (this.distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

      if (this.distance < 0) {
        clearInterval(this.counterInterval);
      }
    }
  },
  created: function() {
    this.getDataFromDate();
  },
  mounted: function() {
    this.counterInterval = setInterval(() => {
      this.getDataFromDate();
    }, 1000);
  },
  destroyed: function() {
    clearInterval(this.counterInterval);
  }
};
</script>
