<template>
  <div :class="['tag-group', className ? className : '']">
    <Tag v-for="tag in data" :key="tag" :value="tag" />
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import "./TagGroup.scss";

export default {
  name: "TagGroup",
  components: {
    Tag
  },
  props: {
    data: Array,
    className: String
  }
};
</script>
