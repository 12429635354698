var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageLayout',{attrs:{"withoutFooter":""}},[_c('Section',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('Title',{attrs:{"level":1}},[_vm._v("Výběr tarifu")]),_c('TextBlock',{staticClass:"text-block--heading-perex",attrs:{"type":"span"}},[_vm._v(" chytré hlasové asistentky ")]),_c('div',{staticClass:"mb-5"},[_c('Section',{attrs:{"id":"cenik"}},[_c('b-row',{staticClass:"align-items-center"},_vm._l((_vm.availableTariffs),function(tariff,index){return _c('b-col',{key:tariff.id,attrs:{"sm":"4"}},[_c('PricingGroup',{attrs:{"highlighted":tariff.data.highlighted,"className":'delay-' + index * 2,"salePrice":tariff.data.salePrice.iv +
                      ' ' +
                      tariff.data.currency.cs +
                      ' / měsíc',"sale":tariff.data.label.cs,"saleColor":tariff.data.color.iv,"price":tariff.data.price.cs +
                      ' ' +
                      tariff.data.currency.cs +
                      ' / měsíc',"title":tariff.data.name,"subtitle":tariff.data.description.cs,"benefits":tariff.data.functions.cs,"selectAction":function () { return _vm.selectTariff({
                        tariffId: tariff.id
                      }); },"order":""}})],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }