var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"platform-carousel align-items-center"},[_c('b-col',{staticClass:"mb-5 mb-md-0",attrs:{"md":"7","lg":"6"}},[_c('div',{staticClass:"mt-md-5 mb-md-5"},[_c('AudioPlayer',{attrs:{"audioName":"TooBizi_2.mp3","title":"Poslechněte si skutečný hovor","inner":true}})],1),_c('h3',{staticClass:"mb-3 mt-4 mt-mb-0"},[_vm._v(" Vzkaz od volajícího vám přijde do ")]),_vm._l((_vm.items),function(ref,index){
var name = ref.name;
var active = ref.active;
var notReady = ref.notReady;
return _c('Button',{key:index,attrs:{"theme":"icon-button","icon":(name + "-icon"),"className":[
        index + 1 !== _vm.items.length ? 'mr-3' : '',
        active ? 'button--active' : ''
      ],"actionHandler":function () { return _vm.setActiveCarouselItem(index); },"notReady":notReady}},[_vm._v(" "+_vm._s(name)+" ")])})],2),_c('b-col',{staticClass:"mt-5 mb-5 mt-md-0 mb-md-0",attrs:{"md":"5","lg":"6"}},[_c('Carousel',{ref:"carousel",on:{"slideChanged":function (index) { return _vm.setActiveCarouselItem(index); }}},_vm._l((_vm.items),function(ref,index){
      var name = ref.name;
return _c('img',{key:index,attrs:{"src":require(("@/assets/images/phone-" + name + ".png")),"alt":("phone " + name)}})}),0),_c('img',{staticClass:"platform-carousel__absolute-img",attrs:{"src":require("@/assets/images/green-figure.svg"),"alt":"green figure"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }