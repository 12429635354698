<template>
  <b-row class="platform-carousel align-items-center">
    <b-col md="7" lg="6" class="mb-5 mb-md-0">
      <div class="mt-md-5 mb-md-5">
        <AudioPlayer
          audioName="TooBizi_2.mp3"
          title="Poslechněte si skutečný hovor"
          v-bind:inner="true"
        />
      </div>

      <h3 class="mb-3 mt-4 mt-mb-0">
        Vzkaz od volajícího vám přijde do
      </h3>
      <Button
        v-for="({ name, active, notReady }, index) in items"
        :key="index"
        theme="icon-button"
        :icon="`${name}-icon`"
        :className="[
          index + 1 !== items.length ? 'mr-3' : '',
          active ? 'button--active' : ''
        ]"
        :actionHandler="() => setActiveCarouselItem(index)"
        :notReady="notReady"
      >
        {{ name }}
      </Button>
    </b-col>
    <b-col md="5" lg="6" class="mt-5 mb-5 mt-md-0 mb-md-0">
      <Carousel
        ref="carousel"
        @slideChanged="index => setActiveCarouselItem(index)"
      >
        <img
          v-for="({ name }, index) in items"
          :key="index"
          :src="require(`@/assets/images/phone-${name}.png`)"
          :alt="`phone ${name}`"
        />
      </Carousel>
      <img
        src="@/assets/images/green-figure.svg"
        alt="green figure"
        class="platform-carousel__absolute-img"
      />
    </b-col>
  </b-row>
</template>

<script>
import Carousel from "@/components/Carousel";
import Button from "@/components/Button";
import AudioPlayer from "@/components/AudioPlayer";
import "./PlatformCarousel.scss";

export default {
  data: () => ({
    items: [
      {
        name: "email",
        active: true
      },
      {
        name: "sms",
        active: true
      }
    ]
  }),
  name: "PlatformCarousel",
  components: {
    Carousel,
    Button,
    AudioPlayer
  },
  methods: {
    setActiveCarouselItem(index) {
      this.$refs.carousel.setActiveCarouselItem(index);
      this.items.map((item, itemIndex) =>
        index === itemIndex ? (item.active = true) : (item.active = false)
      );
    }
  }
};
</script>
