// API states
export const ACTION_SUCCESS = "success";
export const ACTION_ERROR = "error";
export const ACTION_FETCHING = "fetching";

// Order module actions
export const EDIT_SCENARIO = "editScenario";
export const GET_SCENARIO_BY_ID = "getScenarioById";
export const GET_SCENARIO_OVERVIEW = "getScenarioOverview";
export const VERIFY_PROMO_CODE = "verifyPromoCode";
export const REMOVE_SCENARIO = "removeScenario";
export const GET_PAYMENT_DATA = "getPaymentData";
export const GET_PAYMENTS_OVERVIEW = "getPaymentsOverview";
export const VERIFY_RECEIVED_PAYMENT = "verifyReceivedPayment";
export const GET_PAYMENT_PDF = "getPaymentPdf";
export const CHANGE_PAYMENT = "changePayment";
export const GET_AVAILABLE_TARIFFS = "getAvailableTariffs";
export const SELECT_TARIFF = "selectTariff";

// User module actions
export const SEND_VERIFY_EMAIL = "sendVerifyEmail";
export const CHECK_VERIFY_EMAIL = "checkVerifyEmail";
export const UPDATE_TOKEN = "updateToken";
export const SEND_CONTACT_MSG = "sendContactMsg";
export const CHECK_AND_SET_USER = "checkAndSetUser";
export const USER_LOGIN = "userLogIn";
export const USER_LOGOUT = "userLogOut";
export const START_REFRESH_TOKEN_INTERVAL = "startRefreshTokenUpdateInterval";
export const STOP_REFRESH_TOKEN_INTERVAL = "stopRefreshTokenUpdateInterval";
export const CHECK_COOKIE_AGREEMENT = "checkCookieAgreement";
export const AGREE_WITH_COOKIE = "agreeWithCookie";
export const EDIT_USER_PERSONAL_DATA = "editUserPersonalData";

// Form module actions
export const SUBMIT_FORM = "submitForm";
