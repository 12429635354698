<template>
  <div class="slider">
    <span class="slider__left-label" v-if="leftTopLabel">{{
      leftTopLabel
    }}</span>
    <VueSlider
      tooltip="none"
      :dotSize="28"
      :height="7"
      :contained="true"
      v-bind="sliderSettings"
      @change="changeHandler"
      ref="slider"
    />
    <span class="slider__right-label" v-if="rightTopLabel">{{
      rightTopLabel
    }}</span>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "./Slider.scss";

export default {
  name: "Slider",
  components: {
    VueSlider
  },
  props: {
    sliderSettings: Object,
    changeHandler: Function,
    leftTopLabel: [String, Number],
    rightTopLabel: [String, Number]
  },
  methods: {
    setSliderValue(val) {
      this.$refs.slider.setValue(val);
    }
  }
};
</script>
