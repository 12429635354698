<template>
  <b-row>
    <b-col md="7" offset-md="2">
      <div v-if="isScenarioActive(currentScenario.scenarioId)">
        <TextBlock type="span" className="d-block text--semibold" color="green">
          Vaše schránka je aktivní na čísle
        </TextBlock>
        <TextBlock
          v-if="currentScenario"
          type="span"
          color="orange"
          className="d-block mb-2 text--semibold"
        >
          {{ phoneNumber }}
        </TextBlock>
      </div>
      <div v-if="!isScenarioActive(currentScenario.scenarioId)">
        <TextBlock
          type="span"
          className="d-block text--semibold"
          color="yellow"
        >
          Vaše schránka je deaktivována
        </TextBlock>
        <Button
          theme="primary"
          isActive
          :actionHandler="() => this.$router.push('/order-payment')"
          className="mt-4 mb-4"
        >
          Aktivovat schránku
        </Button>
      </div>
      <TextBlock v-if="currentScenario" type="span" className="d-block mt-4">{{
        userEmail
      }}</TextBlock>
      <TextBlock v-if="currentScenario" type="span" className="d-block">
        Cena {{ currentScenario.price }} Kč
        {{ this.currentTariff.data.duration === 1 ? "ročně" : "měsíčně" }}
      </TextBlock>
      <TextBlock
        v-if="currentTariff.data.featureSms"
        type="span"
        className="d-block"
      >
        SMS kanál
      </TextBlock>
      <TextBlock
        v-if="currentTariff.data.featureEmail"
        type="span"
        className="d-block mb-2"
      >
        E-mail kanál
      </TextBlock>
      <Button
        theme="link"
        :actionHandler="
          () =>
            this.$router.push(`/edit-scenario/${currentScenario.scenarioId}`)
        "
        className="mt-4 mb-4 mr-4"
      >
        Upravit scénář
      </Button>
      <Button
        theme="link"
        :actionHandler="() => this.$router.push('/payments-overview')"
        className="mt-4 mb-4"
      >
        Správa plateb
      </Button>
    </b-col>
    <b-col md="5" offset-md="2" class="mt-5">
      <NotificationBox
        v-if="isScenarioActive(currentScenario.scenarioId)"
        type="info"
        className="mb-2"
      >
        Vaše členství je aktivní do
        {{ formatDate(currentScenario.validUntil) }}.
        <span v-if="reccurentPaymentEnable"
          >Po provedení následující platby bude platnost automaticky
          prodloužena.</span
        >
      </NotificationBox>
      <NotificationBox
        v-if="!isScenarioActive(currentScenario.scenarioId)"
        type="warning"
        className="mb-2"
      >
        Vaše členství není aktivní. Pro jeho obnovu je potřeba provést další
        platbu.
      </NotificationBox>
      <Button
        theme="link"
        className="mr-4"
        :actionHandler="() => toggleDialog(dialogs.SCENARIO_DEACTIVATE_DIALOG)"
      >
        Zrušit členství
      </Button>
      <Button theme="link" :actionHandler="() => $router.push('/contact')">
        Kontaktovat podporu
      </Button>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import NotificationBox from "@/components/NotificationBox";

import formatDate from "@/helpers/formatDate";
import openExternalUrl from "@/helpers/openExternalUrl";
import formatPhoneNumber from "@/helpers/formatPhoneNumber";
import { SCENARIO_DEACTIVATE_DIALOG } from "@/constants/dialogs";
import { USER_MODULE, DIALOG_MODULE, ORDER_MODULE } from "@/constants/modules";

export default {
  name: "OrderAdministration",
  data: () => ({
    formatDate,
    openExternalUrl,
    formatPhoneNumber,
    dialogs: { SCENARIO_DEACTIVATE_DIALOG },
    phoneNumber: process.env.VUE_APP_PHONE_NUMBER
  }),
  components: {
    TextBlock,
    Button,
    NotificationBox
  },
  computed: {
    ...mapGetters(USER_MODULE, ["userEmail"]),
    ...mapGetters(ORDER_MODULE, [
      "currentScenario",
      "isScenarioActive",
      "reccurentPaymentEnable",
      "currentTariff"
    ])
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"])
  }
};
</script>
