export const LOGIN_FORM = "loginForm";
export const SCENARIO_FORM = "scenarioForm";
export const TARIFF_FORM = "tariffForm";
export const PAYMENT_FORM = "paymentForm";
export const CONTACT_FORM = "contactForm";
export const PERSONAL_DATA_FORM = "personalDataForm";

export const LOGIN_FROM_DEFAULT = { login: "" };

export const PERSONAL_DATA_FORM_DEFAULT = {
  name: "",
  surname: "",
  street: "",
  city: "",
  zip: "",
  registrationNo: ""
};

export const TARIFF_FORM_DEFAULT = {
  tariff: null
};

export const SCENARIO_FORM_DEFAULT = {
  greetings:
    "Dobrý den, tady virtuální asistentka Honzy Nováka. Bohužel teď nemůže zvednout telefon, ale ráda mu předám Váš vzkaz.",
  farewell: "Zprávu předám, děkuji a přeji hezký den!",
  phoneNumber: "+420"
};

export const PAYMENT_FORM_DEFAULT = {
  promocode: "",
  conditionsAgreement: false,
  gdprAgreement: false
};

export const CONTACT_FORM_DEFAULT = { contactMessage: "" };
