<template>
  <DialogWindow
    :name="dialogs.GDPR_DIALOG"
    title="Chytrá asistentka TooBizi je plně diskrétní"
  >
    <TextBlock type="p">
      Nikdo kromě vás nemá přístup k tomu, kdo vám volá, z jakého čísla a s
      jakým vzkazem. <br />
      Tyto informace zobrazíte jen vy sami v kanále, který si zvolíte (SMS /
      email / jiný kanál)
    </TextBlock>
    <TextBlock type="p">
      Veškerá data jsou zašifrována a bezpečně uložena na našich serverech.
    </TextBlock>
    <TextBlock type="p">
      S telefonní číslem, na které si přesměrujete chytrou hlasovou schránku,
      nijak nenakládáme.
    </TextBlock>
    <TextBlock type="p">
      <ExternalLink :to="require('@/assets/pdf/tb_osobni_udaje.pdf')">
        Ochrana osobních údajů
      </ExternalLink>
    </TextBlock>
    <Button
      theme="primary"
      isActive
      :actionHandler="() => toggleDialog(dialogs.GDPR_DIALOG)"
      className="d-block mx-auto mt-5"
    >
      Rozumím
    </Button>
  </DialogWindow>
</template>

<script>
import { mapMutations } from "vuex";
import DialogWindow from "@/components/DialogWindow";
import TextBlock from "@/components/TextBlock";
import ExternalLink from "@/components/ExternalLink";
import Button from "@/components/Button";
import { GDPR_DIALOG } from "@/constants/dialogs";
import { DIALOG_MODULE } from "@/constants/modules";

export default {
  name: "GdprDialog",
  components: {
    DialogWindow,
    TextBlock,
    ExternalLink,
    Button
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"])
  },
  data: () => ({
    dialogs: { GDPR_DIALOG }
  })
};
</script>
