<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col class="text-center">
          <Title :level="1" className="mb-5">Pages overview</Title>
          <Button
            v-for="(page, index) in pages"
            :key="index"
            theme="primary"
            isActive
            className="mr-3 mt-3"
            :actionHandler="() => $router.push(page.url)"
          >
            {{ page.name }}
          </Button>
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import Button from "@/components/Button";

export default {
  name: "PagesOverview",
  data: () => ({
    pages: [
      { name: "Homepage", url: "/" },
      { name: "Login", url: "/login" },
      { name: "Verify send", url: "/verify-send" },
      { name: "Verify check", url: "/verify-check" },
      { name: "Scenario order", url: "/order-scenario" },
      { name: "Order payment", url: "/order-payment" },
      { name: "Membership", url: "/order-overview" },
      { name: "Contact", url: "/contact" },
      { name: "Contact send", url: "/contact-send" },
      { name: "Scenario removed", url: "/scenario-removed" },
      { name: "Logout", url: "/logout" },
      { name: "404", url: "/404" }
    ]
  }),
  components: {
    PageLayout,
    Title,
    Section,
    Button
  },
  methods: {
    ...mapActions("userModule", ["userLogIn"]),
    ...mapMutations("userModule", ["setUserAuthenticated"])
  },
  created() {
    const scenarioId = "123456789";
    const currentDate = new Date().toJSON().slice(0, 10);

    this.userLogIn({
      email: "test@test.cz",
      accessToken: "",
      refreshToken: "",
      scenarios: [
        {
          scenarioId,
          validUntil: `${currentDate}T23:59:59.102Z`,
          notification: "email",
          data: "",
          phoneNumber: "00420777666555",
          price: 0,
          active: true
        }
      ]
    });
  }
};
</script>
