var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormContainer',[_c('FormGroup',{attrs:{"form":_vm.forms.LOGIN_FORM,"name":"login","placeholder":"Zadejte email","validate":[_vm.validations.noEmpty, _vm.validations.email]},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),(_vm.isActionFetching(_vm.actions.SEND_VERIFY_EMAIL))?_c('Loader'):_c('Button',{attrs:{"theme":"link","actionHandler":function () {
        this$1.gtmTrack();
        this$1[_vm.actions.SUBMIT_FORM]({
          form: _vm.forms.LOGIN_FORM,
          action: function (ref) {
                var login = ref.login;

                return this$1[_vm.actions.SEND_VERIFY_EMAIL](login);
        }
        });
      }}},[_vm._v(" Přihlásit se ")]),(_vm.isActionError(_vm.actions.SEND_VERIFY_EMAIL))?_c('TextBlock',{attrs:{"type":"span","className":"mt-3","errorMessage":""}},[_vm._v(" Při odesílání formuláře došlo k chybě. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }