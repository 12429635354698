<template>
  <div
    :class="['action-strip', disabled ? 'action-strip--disabled' : '']"
    @click="actionHandler"
  >
    <slot />
  </div>
</template>

<script>
import "./ActionStrip.scss";

export default {
  name: "ActionStrip",
  props: {
    actionHandler: Function,
    disabled: Boolean
  }
};
</script>
