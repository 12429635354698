import stateHandlers from "./stateHandlers";
import actions from "./actions";

const userModule = {
  namespaced: true,
  state: {
    isUserAuthenticated: false,
    accessToken: "",
    refreshToken: "",
    refreshTokenUpdateInterval: null,
    userEmail: "",
    userPersonalData: {},
    isCookieAgreed: false
  },
  ...stateHandlers,
  actions
};

export default userModule;
