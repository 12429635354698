<template>
  <DialogWindow
    :name="dialogs.SCENARIO_DEACTIVATE_DIALOG"
    title="Deaktivace hlasové asistentky"
  >
    <TextBlock type="p">
      Opravdu chcete zrušit hlasovou asistentku?
    </TextBlock>
    <TextBlock type="p">
      Hlasovou schránku již nebude možné dále využívat. Pokud chcete hlasovou
      schránku zachovat, můžete v sekci "Správa plateb" pouze pozastavit platbu.
      Pokud jste při objednávce využili promocode nebude ho již možné využít
      znova.
    </TextBlock>
    <div class="text-center">
      <Button
        theme="link"
        isActive
        :actionHandler="() => toggleDialog(dialogs.SCENARIO_DEACTIVATE_DIALOG)"
        className="mt-5 mr-5"
      >
        Zpět
      </Button>
      <Button
        theme="primary"
        isActive
        :actionHandler="() => this[actions.REMOVE_SCENARIO](currentScenario)"
        className="mt-5"
      >
        Potvrdit
      </Button>
    </div>
  </DialogWindow>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import DialogWindow from "@/components/DialogWindow";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import { SCENARIO_DEACTIVATE_DIALOG } from "@/constants/dialogs";
import { DIALOG_MODULE, ORDER_MODULE } from "@/constants/modules";
import { REMOVE_SCENARIO } from "@/constants/actions";

export default {
  name: "ScenarioDeactivateDialog",
  components: {
    DialogWindow,
    TextBlock,
    Button
  },
  data: () => ({
    dialogs: { SCENARIO_DEACTIVATE_DIALOG },
    actions: { REMOVE_SCENARIO }
  }),
  computed: {
    ...mapGetters(ORDER_MODULE, ["currentScenario"])
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"]),
    ...mapActions(ORDER_MODULE, [REMOVE_SCENARIO])
  }
};
</script>
