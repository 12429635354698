import Vue from "vue";
import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from "@/constants/global";

const expireTime = `${process.env.VUE_APP_SESSION_COOKIES_EXPIRE_MINUTES}min`;

export const setTokenCookies = (accessToken, refreshToken) => {
  Vue.$cookies.set(ACCESS_TOKEN_COOKIE, accessToken, expireTime, true);
  Vue.$cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, expireTime, true);
};

export const removeTokenCookies = () => {
  Vue.$cookies.remove(ACCESS_TOKEN_COOKIE);
  Vue.$cookies.remove(REFRESH_TOKEN_COOKIE);
};
