<template>
  <PageLayout withoutFooter withBottomStrip>
    <Section>
      <b-row>
        <b-col md="7" offset-md="2">
          <Title :level="1">Správa plateb</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="7" offset-md="2">
          <CardSummary />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset-md="2" class="mt-2 mb-5">
          <Button
            v-if="
              isScenarioActive(currentScenario.scenarioId) &&
                reccurentPaymentEnable
            "
            theme="link"
            className="mr-4"
            :actionHandler="() => openDialog()"
          >
            Zrušit platbu
          </Button>
          <Button
            v-if="
              isScenarioActive(currentScenario.scenarioId) &&
                !reccurentPaymentEnable
            "
            theme="link"
            className="mr-4"
            :actionHandler="() => openDialog()"
          >
            Obnovit platbu
          </Button>
          <Button
            v-if="!isScenarioActive(currentScenario.scenarioId)"
            theme="link"
            className="mr-4"
            :actionHandler="() => $router.push('/order-payment')"
          >
            Obnovit platbu
          </Button>
          <Button
            theme="link"
            :actionHandler="() => $router.push('/edit-personal-data')"
          >
            Upravit fakturační údaje
          </Button>
        </b-col>
      </b-row>
      <PaymentsSummary />
    </Section>
    <ChangePaymentDialog :isPaymentActive="reccurentPaymentEnable" />
    <ActionStrip :actionHandler="() => $router.push('/order-overview')"
      >Zpět k přehledu</ActionStrip
    >
  </PageLayout>
</template>

<script>
import PageLayout from "@/components/PageLayout";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import PaymentsSummary from "@/components/PaymentsSummary";
import CardSummary from "@/components/CardSummary";
import ActionStrip from "@/components/ActionStrip";
import ChangePaymentDialog from "@/components/ChangePaymentDialog";
import { mapGetters, mapMutations } from "vuex";
import { ORDER_MODULE, DIALOG_MODULE, API_MODULE } from "@/constants/modules";
import { CHANGE_PAYMENT_DIALOG } from "@/constants/dialogs";
import { CHANGE_PAYMENT } from "@/constants/actions";

export default {
  name: "PaymentsOverview",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    PaymentsSummary,
    CardSummary,
    Button,
    ActionStrip,
    ChangePaymentDialog
  },
  computed: {
    ...mapGetters(ORDER_MODULE, [
      "currentScenario",
      "isScenarioActive",
      "reccurentPaymentEnable"
    ])
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"]),
    ...mapMutations(API_MODULE, ["resetActionState"]),

    openDialog() {
      this.toggleDialog(CHANGE_PAYMENT_DIALOG);
      this.resetActionState(CHANGE_PAYMENT);
    }
  }
};
</script>
