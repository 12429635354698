<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="12">
          <Title :level="1">Výběr tarifu</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
          <div class="mb-5">
            <Section id="cenik">
              <b-row class="align-items-center">
                <b-col
                  sm="4"
                  v-for="(tariff, index) in availableTariffs"
                  :key="tariff.id"
                >
                  <PricingGroup
                    :highlighted="tariff.data.highlighted"
                    :className="'delay-' + index * 2"
                    :salePrice="
                      tariff.data.salePrice.iv +
                        ' ' +
                        tariff.data.currency.cs +
                        ' / měsíc'
                    "
                    :sale="tariff.data.label.cs"
                    :saleColor="tariff.data.color.iv"
                    :price="
                      tariff.data.price.cs +
                        ' ' +
                        tariff.data.currency.cs +
                        ' / měsíc'
                    "
                    :title="tariff.data.name"
                    :subtitle="tariff.data.description.cs"
                    :benefits="tariff.data.functions.cs"
                    :selectAction="
                      () =>
                        selectTariff({
                          tariffId: tariff.id
                        })
                    "
                    order
                  />
                </b-col>
              </b-row>
            </Section>
          </div>
        </b-col>
      </b-row>
    </Section>
  </PageLayout>
</template>

<script>
// import { mapGetters } from "vuex";
// import { TARIFF_FORM } from "@/constants/forms";
import { ORDER_MODULE } from "@/constants/modules";

import PageLayout from "@/components/PageLayout";
// import TariffForm from "@/components/TariffForm";
import Title from "@/components/Title";
import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import PricingGroup from "@/components/PricingGroup";
import { mapActions, mapGetters } from "vuex";
import { SELECT_TARIFF } from "@/constants/actions";

export default {
  name: "OrderTariff",
  components: {
    PageLayout,
    Title,
    Section,
    TextBlock,
    PricingGroup
  },
  data: () => ({
    // forms: { TARIFF_FORM }
    actions: { SELECT_TARIFF }
  }),
  methods: {
    ...mapActions(ORDER_MODULE, [SELECT_TARIFF])
  },
  computed: {
    ...mapGetters(ORDER_MODULE, ["availableTariffs"])
  }
};
</script>
