<template>
  <PageLayout withoutFooter>
    <Section>
      <b-row>
        <b-col md="6" offset-md="2">
          <Title :level="1">Scénář</Title>
          <TextBlock type="span" class="text-block--heading-perex">
            chytré hlasové asistentky
          </TextBlock>
          <ScenarioForm ref="scenarioForm" />
        </b-col>
      </b-row>
    </Section>
    <ActionStrip
      :actionHandler="sendScenarioForm"
      :disabled="!isFormValid(forms.SCENARIO_FORM)"
    >
      Přejít k zaplacení / aktivaci
      <!-- TODO -->
    </ActionStrip>
  </PageLayout>
</template>

<script>
import { mapGetters } from "vuex";

import { SCENARIO_FORM } from "@/constants/forms";
import { FORM_MODULE } from "@/constants/modules";

import PageLayout from "@/components/PageLayout";
import ScenarioForm from "@/components/ScenarioForm";
import Title from "@/components/Title";
import Section from "@/components/Section";
import ActionStrip from "@/components/ActionStrip";
import TextBlock from "@/components/TextBlock";

export default {
  name: "OrderScenario",
  components: {
    PageLayout,
    ScenarioForm,
    Title,
    Section,
    ActionStrip,
    TextBlock
  },
  data: () => ({
    forms: { SCENARIO_FORM }
  }),
  methods: {
    sendScenarioForm() {
      this.$refs.scenarioForm.sendScenarioForm();
    }
  },
  computed: {
    ...mapGetters(FORM_MODULE, ["isFormValid"])
  }
};
</script>
