const stateHandlers = {
  getters: {
    getFormValues: state => form => state[form].values,
    isFormValid: state => form => state[form].errorFields.length === 0,
    isFormSubmitTouched: state => form => state[form].submitTouched
  },
  mutations: {
    setFormFieldValue: (state, { form, field, value }) =>
      (state[form].values = { ...state[form].values, [field]: value }),

    setFormValues: (state, { form, values }) => (state[form].values = values),

    addFormError: (state, { form, errorField }) => {
      state[form].errorFields.includes(errorField)
        ? null
        : state[form].errorFields.push(errorField);
    },

    removeFormError: (state, { form, errorField }) =>
      (state[form].errorFields = state[form].errorFields.filter(
        err => err !== errorField
      )),

    setFormSubmitTouched: (state, form) => (state[form].submitTouched = true),

    setFormUntouched: (state, form) => (state[form].submitTouched = false)
  }
};

export default stateHandlers;
