import { SUBMIT_FORM } from "@/constants/actions";

const actions = {
  [SUBMIT_FORM]({ commit, getters }, { form, action }) {
    commit("setFormSubmitTouched", form);

    if (getters.isFormValid(form)) {
      const formValues = getters.getFormValues(form);
      action(formValues);
    }
  }
};

export default actions;
