import Vue from "vue";
import store from "@/store";
import { CHECK_AND_SET_USER } from "@/constants/actions";
import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from "@/constants/global";
import { USER_MODULE, ORDER_MODULE } from "@/constants/modules";
import router from "./router";

export const accessNavigationGuard = (
  next,
  { withAuthentication, ...flowSettings }
) => {
  const accessTokenCookie = Vue.$cookies.get(ACCESS_TOKEN_COOKIE);
  const refreshTokenCookie = Vue.$cookies.get(REFRESH_TOKEN_COOKIE);
  const isUserAuthenticated =
    store.getters[`${USER_MODULE}/isUserAuthenticated`];

  if (isUserAuthenticated) {
    flowCheckGuard(next, flowSettings);
  } else {
    !withAuthentication ? next() : null;

    if (accessTokenCookie) {
      store.dispatch(`${USER_MODULE}/${CHECK_AND_SET_USER}`, {
        redirectToPage: () => flowCheckGuard(next, flowSettings),
        accessToken: accessTokenCookie,
        refreshToken: refreshTokenCookie,
        restrictedPage: withAuthentication
      });
      return;
    }

    withAuthentication ? next("/unauthorized") : null;
  }
};

export const flowCheckGuard = (next, { withScenario, withTariff }) => {
  if (withScenario === undefined) {
    next();
    return;
  }

  if (withTariff) {
    store.getters[`${ORDER_MODULE}/currentTariffId`]
      ? next()
      : router.push("/order-tariff");
    return;
  }

  if (withScenario) {
    store.getters[`${ORDER_MODULE}/hasOrderScenario`]
      ? next()
      : router.push("/order-personal-data");
    return;
  }

  if (!withScenario) {
    store.getters[`${ORDER_MODULE}/hasOrderScenario`]
      ? router.push("/order-overview")
      : next();
    return;
  }
};

export const testModeNavigationGuard = next =>
  process.env.VUE_APP_TEST_MODE ? next() : next("/");
