<template>
  <div class="audio-player" :class="inner && 'audio-player--inner'">
    <span class="audio-player__title mr-4" v-if="title && !inner"
      >{{ title }} -</span
    >
    <div class="audio-player__controls">
      <span class="audio-player__title mr-4" v-if="title && inner">{{
        title
      }}</span>
      <PlayButton :actionHandler="audioHandler" :isPlaying="isPlaying" />
      <Slider
        ref="slider"
        :sliderSettings="sliderSettings"
        :changeHandler="sliderChanged"
        :leftTopLabel="convertToTime(audioCurrentTime)"
        :rightTopLabel="convertToTime(audioDuration)"
      />
      <p class="audio-player__description" v-if="inner">
        nebo vyzkoušejte sami na čísle <a href="tel:228227516"> 228 227 516</a>
      </p>
    </div>
  </div>
</template>

<script>
import PlayButton from "@/components/PlayButton";
import Slider from "@/components/Slider";
import "./AudioPlayer.scss";

export default {
  name: "AudioPlayer",
  components: {
    PlayButton,
    Slider
  },
  props: {
    audioName: String,
    title: String,
    inner: Boolean
  },
  data: ({ audioName }) => ({
    isPlaying: false,
    audio: new Audio(require(`@/assets/audio/${audioName}`)),
    timer: null,
    audioDuration: 0,
    audioCurrentTime: 0,
    sliderSettings: {
      min: 0,
      max: 60,
      step: 1,
      lazy: true,
      "drag-on-click": true
    }
  }),
  mounted() {
    this.audio.onloadedmetadata = () => {
      this.sliderSettings.max = Math.round(this.audio.duration);
      this.audioDuration = this.audio.duration;
    };

    this.audio.onended = () => {
      this.isPlaying = false;
    };
  },
  methods: {
    audioHandler() {
      !this.isPlaying &&
      this.audio.currentTime === Math.round(this.audio.duration)
        ? (this.audio.currentTime = 0)
        : null;

      this.isPlaying ? this.audio.pause() : this.audio.play();
      this.isPlaying = !this.isPlaying;
    },
    sliderChanged(value) {
      Math.round(this.audio.currentTime) !== value
        ? (this.audio.currentTime = value)
        : null;

      this.audioCurrentTime = Math.round(this.audio.currentTime);
    },
    convertToTime(value) {
      const roundedValue = Math.round(value);
      const formatSeconds = seconds => (seconds > 9 ? seconds : `0${seconds}`);

      if (roundedValue >= 60) {
        const minutes = Math.floor(roundedValue / 60);
        const seconds = roundedValue - minutes * 60;

        return `${minutes}:${formatSeconds(seconds)}`;
      }

      return `0:${formatSeconds(roundedValue)}`;
    },
    setSliderValue(value) {
      this.$refs.slider.setSliderValue(value);
    }
  },
  watch: {
    isPlaying: function(isPlaying) {
      if (isPlaying) {
        this.setSliderValue(this.audio.currentTime);

        this.timer = setInterval(() => {
          const currentTime = Math.round(this.audio.currentTime);
          this.setSliderValue(currentTime);
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    }
  }
};
</script>
