<template>
  <div class="loader">
    <div class="loader__item"></div>
    <div class="loader__item"></div>
    <div class="loader__item"></div>
    <div class="loader__item"></div>
  </div>
</template>

<script>
import "./Loader.scss";

export default {
  data() {
    return {};
  },
  name: "Loader",
  props: {},
  methods: {}
};
</script>
