<template>
  <div class="footer">
    <b-container>
      <b-row>
        <b-col
          md="4"
          class="text-center text-md-left order-2 order-md-1 mb-5 mb-md-0"
        >
          <span>Dotazy a připomínky:</span>
          <br />
          <ExternalLink to="mailto:jsem@toobizi.cz" :openInNewWindow="false"
            >jsem@toobizi.cz
          </ExternalLink>
          <br />
          <ExternalLink to="tel:+420 704 494 034" :openInNewWindow="false"
            >+420 704 494 034
          </ExternalLink>
        </b-col>
        <b-col md="4" class="text-center order-1 order-md-2 mb-5 mb-md-0">
          Používáme technologii
          <img src="@/assets/images/logo-vocalls.svg" alt="Vocalls" /><br />
          Telefonní asistent s umělou inteligencí
          <TextBlock type="span" class="mt-4 d-block">
            {{ new Date().getFullYear() }} - Toobizi
          </TextBlock>
        </b-col>
        <b-col md="4" class="text-center text-md-right order-3">
          <div>
            <router-link to="/faq" class="link">
              Často kladené dotazy
            </router-link>
          </div>
          <ExternalLink :to="require('@/assets/pdf/tb_cookies.pdf')">
            Prohlášení o cookies
          </ExternalLink>
          <br />
          <ExternalLink :to="require('@/assets/pdf/tb_osobni_udaje.pdf')">
            Ochrana osobních údajů
          </ExternalLink>
          <br />
          <ExternalLink :to="require('@/assets/pdf/tb_obchodni_podminky.pdf')">
            Všeobecné obchodní podmínky
          </ExternalLink>
          <br />
          <img src="@/assets/images/the-pay.svg" alt="The pay" class="pt-3" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ExternalLink from "@/components/ExternalLink";
import TextBlock from "@/components/TextBlock";
import "./Footer.scss";

export default {
  name: "Footer",
  components: {
    ExternalLink,
    TextBlock
  }
};
</script>
