<script>
import "./Title.scss";

export default {
  name: "Title",
  render: function(createElement) {
    return createElement(
      "h" + this.level,
      {
        class: `title${this.className ? " " + this.className : ""}`
      },
      this.$slots.default
    );
  },
  props: {
    level: Number,
    className: String
  }
};
</script>
