import Vue from "vue";
import router from "@/router";
import createRequest from "@/helpers/createRequest";
import { setTokenCookies, removeTokenCookies } from "@/helpers/tokenCookies";
import {
  USER_LOGIN,
  USER_LOGOUT,
  START_REFRESH_TOKEN_INTERVAL,
  STOP_REFRESH_TOKEN_INTERVAL,
  CHECK_COOKIE_AGREEMENT,
  AGREE_WITH_COOKIE,
  SEND_VERIFY_EMAIL,
  CHECK_VERIFY_EMAIL,
  UPDATE_TOKEN,
  SEND_CONTACT_MSG,
  CHECK_AND_SET_USER,
  EDIT_USER_PERSONAL_DATA,
  GET_AVAILABLE_TARIFFS
} from "@/constants/actions";
import { ORDER_MODULE } from "@/constants/modules";
import { COOKIE_AGREEMENT } from "@/constants/global";

const API_URL = process.env.VUE_APP_API_URL;

const actions = {
  [SEND_VERIFY_EMAIL]: (_context, email) =>
    createRequest({
      action: SEND_VERIFY_EMAIL,
      method: "POST",
      url: `${API_URL}/User/CreateEmailVerification`,
      data: { email },
      success: () => router.push("/verify-send")
    }),

  [CHECK_VERIFY_EMAIL]: ({ dispatch }, code) =>
    createRequest({
      action: CHECK_VERIFY_EMAIL,
      method: "POST",
      url: `${API_URL}/User/VerifyEmail`,
      data: { code },

      success: ({ refreshToken, accessToken }) => {
        dispatch(CHECK_AND_SET_USER, { refreshToken, accessToken });
      }
    }),

  [UPDATE_TOKEN]: ({ commit, getters }) => {
    createRequest({
      action: UPDATE_TOKEN,
      method: "POST",
      url: `${API_URL}/User/RefreshToken`,
      withAutorization: true,
      data: {
        token: getters["refreshToken"]
      },
      success: ({ accessToken, refreshToken }) => {
        commit("setAccessToken", accessToken);
        commit("setRefreshToken", refreshToken);
        setTokenCookies(accessToken, refreshToken);
      },
      error: () => removeTokenCookies()
    });
  },

  [SEND_CONTACT_MSG]: ({ getters }, message) => {
    createRequest({
      action: SEND_CONTACT_MSG,
      method: "POST",
      url: `${API_URL}/Home/Support`,
      withAutorization: true,
      data: {
        sender: getters["userEmail"],
        message
      },
      success: () => router.push("/contact-send")
    });
  },

  [CHECK_AND_SET_USER]: (
    { dispatch, commit },
    { redirectToPage, accessToken, refreshToken, restrictedPage }
  ) => {
    commit("setAccessToken", accessToken);

    createRequest({
      action: CHECK_AND_SET_USER,
      method: "GET",
      url: `${API_URL}/User/Overview`,
      withAutorization: true,
      success: ({ ...responseData }) => {
        dispatch(
          `${ORDER_MODULE}/${GET_AVAILABLE_TARIFFS}`,
          {},
          { root: true }
        );
        dispatch(USER_LOGIN, { accessToken, refreshToken, ...responseData });

        restrictedPage ? redirectToPage() : null;
      },
      error: () => {
        commit("setAccessToken", "");
        removeTokenCookies();
        restrictedPage ? router.push("/unauthorized") : null;
      }
    });
  },

  [EDIT_USER_PERSONAL_DATA]: ({ commit }, userData) => {
    createRequest({
      action: EDIT_USER_PERSONAL_DATA,
      method: "POST",
      url: `${API_URL}/User/Edit`,
      data: userData,
      withAutorization: true,
      success: () => {
        commit("setUserPersonalData", userData);
        router.push("/order-tariff");
      }
    });
  },

  [USER_LOGIN]: (
    { commit, dispatch },
    {
      email,
      accessToken,
      refreshToken,
      scenarios,
      reccurentPaymentEnable,
      ...userData
    }
  ) => {
    if (userData) {
      const {
        name,
        street,
        city,
        zip,
        tarif,
        registrationNo,
        vatNo
      } = userData;
      commit("setUserPersonalData", {
        name,
        street,
        city,
        zip,
        registrationNo,
        vatNo,
        tarif
      });
      // toto zvazit, jestli je treba to mit v ORDER_MODULE, protoze je to i v datech o uzivateli
      commit(`${ORDER_MODULE}/setCurrentTariffId`, tarif, { root: true });
    }

    commit("setUserAuthenticated", true);
    commit("setAccessToken", accessToken);
    commit("setRefreshToken", refreshToken);
    commit("setUserEmail", email);
    commit(`${ORDER_MODULE}/setScenarios`, scenarios, { root: true });
    commit(`${ORDER_MODULE}/changeReccurentPayment`, reccurentPaymentEnable, {
      root: true
    });

    scenarios.length > 0
      ? commit(`${ORDER_MODULE}/setCurrentScenario`, scenarios[0].scenarioId, {
          root: true
        })
      : null;

    dispatch(START_REFRESH_TOKEN_INTERVAL);
    setTokenCookies(accessToken, refreshToken);
  },

  [USER_LOGOUT]: ({ commit, dispatch }) => {
    commit("setUserAuthenticated", false);
    commit("setUserEmail", "");
    commit("setAccessToken", "");
    commit("setRefreshToken", "");
    commit(`${ORDER_MODULE}/setScenarios`, [], { root: true });

    dispatch(STOP_REFRESH_TOKEN_INTERVAL);
    removeTokenCookies();

    router.push("/logout");
  },

  [START_REFRESH_TOKEN_INTERVAL]: ({ state, dispatch }) =>
    (state.refreshTokenUpdateInterval = setInterval(
      () => dispatch(UPDATE_TOKEN),
      process.env.VUE_APP_REFRESH_TOKEN_INTERVAL
    )),

  [STOP_REFRESH_TOKEN_INTERVAL]: ({ state }) =>
    (state.refreshTokenUpdateInterval = clearInterval(
      state.refreshTokenUpdateInterval
    )),

  [CHECK_COOKIE_AGREEMENT]: ({ commit, dispatch }) => {
    Vue.$cookies.get(COOKIE_AGREEMENT)
      ? commit("setCookieAgreed", true)
      : commit("setCookieAgreed", false);
    dispatch(`${ORDER_MODULE}/${GET_AVAILABLE_TARIFFS}`, {}, { root: true });
  },
  [AGREE_WITH_COOKIE]: ({ commit }) => {
    Vue.$cookies.set(COOKIE_AGREEMENT, true, Infinity);
    commit("setCookieAgreed", true);
  }
};

export default actions;
