<template>
  <div class="gdpr-stripe">
    <Section :offset="['without-bottom', 'without-top']">
      <b-row class="align-items-center">
        <b-col md="3" lg="2" class="d-none d-sm-none d-md-block">
          <img
            src="@/assets/images/gdpr-logo.svg"
            alt="gdpr"
            class="gdpr-stripe__logo"
          />
        </b-col>
        <b-col
          cols="12"
          md="9"
          lg="7"
          class="text-center text-md-left mb-3 mb-lg-0"
        >
          <TextBlock type="span" className="gdpr-stripe__title">
            Informace o zpracování osobních údajů
          </TextBlock>
        </b-col>
        <b-col md="12" lg="3" class="text-center text-lg-right">
          <Button
            theme="primary"
            isActive
            :actionHandler="() => toggleDialog(dialogs.GDPR_DIALOG)"
          >
            Seznámit se
          </Button>
        </b-col>
      </b-row>
    </Section>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Section from "@/components/Section";
import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import { GDPR_DIALOG } from "@/constants/dialogs";
import { DIALOG_MODULE } from "@/constants/modules";

import "./GdprStripe.scss";

export default {
  name: "GdprStripe",
  data: () => ({
    dialogs: { GDPR_DIALOG }
  }),
  components: {
    Section,
    TextBlock,
    Button
  },
  methods: {
    ...mapMutations(DIALOG_MODULE, ["toggleDialog"])
  }
};
</script>
