import {
  SEND_VERIFY_EMAIL,
  CHECK_VERIFY_EMAIL,
  EDIT_SCENARIO,
  GET_SCENARIO_OVERVIEW,
  VERIFY_PROMO_CODE,
  REMOVE_SCENARIO,
  UPDATE_TOKEN,
  SEND_CONTACT_MSG,
  CHECK_AND_SET_USER,
  GET_PAYMENT_DATA,
  GET_PAYMENTS_OVERVIEW,
  VERIFY_RECEIVED_PAYMENT,
  GET_PAYMENT_PDF,
  EDIT_USER_PERSONAL_DATA,
  CHANGE_PAYMENT
} from "@/constants/actions";
import stateHandlers from "./stateHandlers";

const apiModule = {
  namespaced: true,
  state: {
    [SEND_VERIFY_EMAIL]: "",
    [CHECK_VERIFY_EMAIL]: "",
    [EDIT_SCENARIO]: "",
    [GET_SCENARIO_OVERVIEW]: "",
    [VERIFY_PROMO_CODE]: "",
    [REMOVE_SCENARIO]: "",
    [UPDATE_TOKEN]: "",
    [SEND_CONTACT_MSG]: "",
    [CHECK_AND_SET_USER]: "",
    [GET_PAYMENT_DATA]: "",
    [GET_PAYMENTS_OVERVIEW]: "",
    [VERIFY_RECEIVED_PAYMENT]: "",
    [GET_PAYMENT_PDF]: "",
    [EDIT_USER_PERSONAL_DATA]: "",
    [CHANGE_PAYMENT]: ""
  },
  ...stateHandlers
};

export default apiModule;
