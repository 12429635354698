<template>
  <div class="cookie-bar">
    <b-container>
      <b-row>
        <b-col>
          <div class="cookie-bar__inner">
            <TextBlock className="cookie-bar__text d-block" type="span">
              Tento web používá cookies. Pokud zůstanete, souhlasíte se všemi
              zásadami pro soubory cookies.
            </TextBlock>
            <Button
              :actionHandler="() => this[actions.AGREE_WITH_COOKIE]()"
              theme="link"
              className="ml-5"
              >Souhlasím</Button
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import TextBlock from "@/components/TextBlock";
import Button from "@/components/Button";
import { AGREE_WITH_COOKIE } from "@/constants/actions";
import { USER_MODULE } from "@/constants/modules";

import "./CookieBar.scss";

export default {
  name: "CookieBar",
  data: () => ({
    actions: { AGREE_WITH_COOKIE }
  }),
  components: {
    TextBlock,
    Button
  },
  methods: {
    ...mapActions(USER_MODULE, [AGREE_WITH_COOKIE])
  }
};
</script>
