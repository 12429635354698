import axios from "axios";
import store from "../store";
import {
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_FETCHING
} from "@/constants/actions";
import { API_MODULE, USER_MODULE } from "@/constants/modules";

const setActionState = (action, actionState) =>
  store.commit(`${API_MODULE}/setActionState`, { action, actionState });

const createRequest = ({
  action,
  withAutorization = false,
  success,
  error,
  ...requestSettings
}) => {
  if (store.getters[`${API_MODULE}/isActionFetching`](action)) {
    return null;
  }

  setActionState(action, ACTION_FETCHING);

  axios({
    ...requestSettings,
    ...(withAutorization && {
      headers: {
        Authorization: `Bearer ${store.getters[`${USER_MODULE}/accessToken`]}`
      }
    })
  })
    .then(({ data }) => {
      setActionState(action, ACTION_SUCCESS);
      success ? success(data) : null;
    })
    .catch(err => {
      setActionState(action, ACTION_ERROR);
      console.error(err);
      error ? error(err) : null;
    });
};

export default createRequest;
