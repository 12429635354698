import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import OrderPersonalData from "../views/OrderPersonalData.vue";
import OrderScenario from "../views/OrderScenario.vue";
import OrderTariff from "../views/OrderTariff.vue";
import NotFound from "../views/NotFound.vue";
import VerifySend from "../views/VerifySend.vue";
import VerifyCheck from "../views/VerifyCheck.vue";
import OrderOverview from "../views/OrderOverview.vue";
import OrderPayment from "../views/OrderPayment.vue";
import VerifyPayment from "../views/VerifyPayment.vue";
import EditScenario from "../views/EditScenario";
import EditPersonalData from "../views/EditPersonalData";
import ScenarioRemoved from "../views/ScenarioRemoved";
import PagesOverview from "../views/PagesOverview";
import PaymentsOverview from "../views/PaymentsOverview";
import ContactSupport from "../views/ContactSupport";
import ContactSend from "../views/ContactSend";
import Unauthorized from "../views/Unauthorized.vue";
import Logout from "../views/Logout.vue";
import Faq from "../views/Faq";
import EditPlan from "../views/EditPlan";

const routesSettings = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { gtm: "Home" }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { gtm: "Login" }
  },
  {
    path: "/verify-send",
    name: "VerifySend",
    component: VerifySend,
    meta: { gtm: "VerifySend" }
  },
  {
    path: "/verify-check",
    name: "VerifyCheck",
    component: VerifyCheck,
    meta: { gtm: "VerifyCheck" }
  },
  {
    path: "/order-personal-data",
    name: "OrderPersonalData",
    component: OrderPersonalData,
    meta: {
      gtm: "OrderPersonalData",
      withAuthentication: true,
      withScenario: false
    }
  },
  {
    path: "/order-scenario",
    name: "OrderScenario",
    component: OrderScenario,
    meta: {
      gtm: "OrderScenario",
      withAuthentication: true,
      withScenario: false,
      withTariff: true
    }
  },
  {
    path: "/order-tariff",
    name: "OrderTariff",
    component: OrderTariff,
    meta: { withAuthentication: true, withScenario: false }
  },
  {
    path: "/order-payment",
    name: "OrderPayment",
    component: OrderPayment,
    meta: { gtm: "OrderPayment", withAuthentication: true, withScenario: true }
  },
  {
    path: "/verify-payment",
    name: "VerifyPayment",
    component: VerifyPayment,
    meta: { gtm: "VerifyPayment", withAuthentication: true, withScenario: true }
  },
  {
    path: "/order-overview",
    name: "OrderOverview",
    component: OrderOverview,
    meta: { gtm: "OrderOverview", withAuthentication: true, withScenario: true }
  },
  {
    path: "/edit-personal-data",
    name: "EditPersonalData",
    component: EditPersonalData,
    meta: {
      gtm: "EditPersonalData",
      withAuthentication: true,
      withScenario: true
    }
  },
  {
    path: "/edit-scenario/:id",
    name: "EditScenario",
    component: EditScenario,
    meta: { gtm: "EditScenario", withAuthentication: true, withScenario: true }
  },
  {
    path: "/edit-plan/:id",
    name: "EditPlan",
    component: EditPlan,
    meta: { gtm: "EditPlan", withAuthentication: true, withScenario: true }
  },
  {
    path: "/payments-overview",
    name: "PaymentsOverview",
    component: PaymentsOverview,
    meta: {
      gtm: "PaymentsOverview",
      withAuthentication: true,
      withScenario: true
    }
  },
  {
    path: "/scenario-removed",
    name: "ScenarioRemoved",
    component: ScenarioRemoved,
    meta: { gtm: "ScenarioRemoved" }
  },
  {
    path: "/contact",
    name: "ContactSupport",
    component: ContactSupport,
    meta: { gtm: "ContactSupport", withAuthentication: true }
  },
  {
    path: "/contact-send",
    name: "ContactSend",
    component: ContactSend,
    meta: { gtm: "ContactSend", withAuthentication: true }
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { gtm: "ScenarioRemoved" }
  },
  {
    path: "/dev",
    name: "PagesOverview",
    component: PagesOverview,
    meta: { gtm: "PagesOverview", testModeOnly: true }
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
    meta: { gtm: "FAQ" }
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
    meta: { gtm: "Unauthorized" }
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: { gtm: "NotFound" }
  }
];

export default routesSettings;
