<template>
  <article
    class="customer-review-item animated customer-review-item--animated"
    :class="className"
  >
    <Title :level="3" class="customer-review-item__title">{{ title }}</Title>
    <TextBlock type="p" class="customer-review-item__text"
      >{{ text }}
    </TextBlock>
    <b-row class="align-items-center">
      <b-col sm="3">
        <img class="img-fluid img-round" :src="image" :alt="name" />
      </b-col>
      <b-col sm="9">
        <div class="customer-review-item__name">{{ name }}</div>
        <div class="customer-review-item__company">{{ position }}</div>
      </b-col>
    </b-row>
  </article>
</template>

<script>
import "./CustomerReview.scss";
import TextBlock from "@/components/TextBlock";
import Title from "@/components/Title";

export default {
  name: "CustomerReview",
  props: {
    title: String,
    text: String,
    name: String,
    position: String,
    image: String,
    className: String
  },
  components: {
    TextBlock,
    Title
  },
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    this.observer.observe(this.$el);
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 0.6
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        this.observer.unobserve(target);
        this.$el.classList.add("fadeInUp");
      });
    }
  }
};
</script>
