<template>
  <div class="carousel">
    <VueSlickCarousel
      ref="carousel"
      :dots="true"
      :slidesToShow="1"
      :arrows="false"
      @beforeChange="(_oldIndex, newIndex) => slideChanged(newIndex)"
    >
      <slot />
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "./Carousel.scss";

export default {
  name: "Carousel",
  components: {
    VueSlickCarousel
  },
  methods: {
    setActiveCarouselItem(index) {
      this.$refs.carousel.goTo(index);
    },
    slideChanged(index) {
      this.$emit("slideChanged", index);
    }
  }
};
</script>
