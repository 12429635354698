<template>
  <div
    :class="['play-button', isPlaying ? 'play-button--active' : '']"
    @click="actionHandler"
  >
    <div class="play-button__inner"></div>
  </div>
</template>

<script>
import "./PlayButton.scss";

export default {
  name: "PlayButton",
  props: {
    actionHandler: Function,
    isPlaying: Boolean
  }
};
</script>
